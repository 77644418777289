import React, { useEffect, useState } from "react";
import { Logs, vaultData } from "../../services";
import { CustomTable, DownloadToken, Wrapper } from "../../components";
import { Box, Button, Typography, Dialog, Divider, IconButton } from "@mui/material";
import { DataColumns } from "./constants";
import { getFormattedDate } from "../../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { VaultDialogBox } from "./vaultDialogBox";

const customTableData = (dataToConvert, handleOpenDialog) => {
  let sno = 1;
  const updatedData = dataToConvert?.map((entry) => {
    const apiName = entry?.attributes?.apiName;
    const formattedEntry = {
      key: entry?.id,
      sno: sno++,
      date: getFormattedDate(entry?.attributes?.createdAt),
      documentName: entry?.attributes?.documentName,
      token: entry?.attributes?.token,
      apiName: entry?.attributes?.apiName,
      action: (
        <Box display="flex" justifyContent="center" alignItems="center" gap="15px">
          <Button
            color="primary"
            onClick={() => handleOpenDialog(entry)}
            disabled={apiName==="Aadhaar Masking"? true : false}
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              minWidth: 'auto',
            }}
          >
            view
          </Button>
          <DownloadToken
            documentName={entry?.attributes?.documentName}
            token={entry?.attributes?.token}
          />
        </Box>
      ),
    };
    return formattedEntry;
  });
  return updatedData;
};

export const VaultTable = () => {
  const [vaultTableData, setVaultTableData] = useState();
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const navigate = useNavigate();

  const getVaultData = async () => {
    try {
      const res = await vaultData();
      if (res?.status === 200) {
        setVaultTableData(customTableData(res?.data?.data, handleOpenDialog));
      }
    } catch (error) {
      Logs(error);
    }
  };

  const handleOpenDialog = (entry) => {
    setCurrentData(entry?.attributes?.response);
    setOpen(true);
  };

  const backToScreenApi = () => {
    navigate("/onscreen-api");
  };

  useEffect(() => {
    getVaultData();
  }, []);

  return (
    <>
      <Wrapper>
        <Typography
          fontSize={22}
          mt={10}
          mb={2}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <ArrowBackIcon
            onClick={backToScreenApi}
            sx={{ fontSize: "25px", cursor: "pointer" }}
          />{" "}
          Vault
        </Typography>
        <CustomTable columns={DataColumns} data={vaultTableData} />
        <VaultDialogBox open={open} setOpen={setOpen} jsonData={currentData} />
      </Wrapper>
    </>
  );
};
