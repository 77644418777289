import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataColumns } from './constants';

import {
  ActiveButton,
  CustomTable,
  ExpiredButton,
  Wrapper,
} from '../../components';
import { Logs, billData } from '../../services';
import { getFormattedDate } from '../../utils';
import { ExhaustedButton } from '../../components/exhaustedButton/ExhaustedButton';
import { InactiveButton } from '../../components/InactiveButton/InactiveButton';

const convertToCustomTableFormat = (dataToConvert) => {
  let serialNumber = 1;
  const updatedData = dataToConvert?.map((entry) => {
    let statusComponent;
    if (entry?.attributes?.status === 'ACTIVE') {
      statusComponent = <ActiveButton />;
    } else if (entry?.attributes?.status === 'EXPIRED') {
      statusComponent = <ExpiredButton />;
    } else if (entry?.attributes?.status === 'EXHAUSTED') {
      statusComponent = <ExhaustedButton />;
    } else {
      statusComponent = <InactiveButton />;
    }

    const formattedEntry = {
      key: entry?.id,
      serialNumber: serialNumber++,
      date: getFormattedDate(entry?.attributes?.createdAt),
      status: statusComponent,
      price: entry?.attributes?.price,
      credits: entry?.attributes?.assignedCredit,
      expired: getFormattedDate(entry?.attributes?.expiryDate),
      validity: entry.attributes?.Validaty,
    };
    return formattedEntry;
  });
  return updatedData;
};

export const Billtable = () => {
  const [billTableData, setData] = useState();
  const { user } = useSelector((state) => state.auth);

  const companyId = user.company.id;

  const getBillData = async () => {
    try {
      const res = await billData({ id: companyId });
      if (res?.status === 200) {
        const sortedData = res?.data?.data.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
        setData(convertToCustomTableFormat(sortedData));
      }
    } catch (error) {
      Logs(error);
    }
  };

  useEffect(() => {
    getBillData();
  }, []);

  return (
    <Wrapper>
      <Typography fontSize={22} mt={10} mb={2}>
        Billing Detail
      </Typography>
      <CustomTable columns={DataColumns} data={billTableData} />
    </Wrapper>
  );
};
