import { Box } from "@mui/material";

export const ExpiredButton = () => {
  return (
    <Box
      sx={{
        borderRadius: 5,
        color: "#FF6868",
        background: "#FFD1D1",
        width: "70px",
        textAlign: "center",
        padding: 0.3,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        fontSize: 12,

      }}
    >
      Expired
    </Box>
  );
};
