import axios from "axios";
import { baseURL, createHeaders } from "./constants";

const contactUs = async (payload) => {
  try {
    return axios.post(`${baseURL}/contact-uses`, { data: { ...payload }}, {headers: createHeaders()},);
  } catch (error) {
    throw error;
  }
};
export { contactUs };
