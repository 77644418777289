import axios, { Axios } from "axios";
import { baseURL, createHeaders } from "./constants";

const keysPair = async (params) => {
  const { id } = params;
  try {
    return axios.get(`${baseURL}/company-api-keys?populate=*&filters[company][id]=${id}`, {
      headers: createHeaders()
    });
  } catch (error) {
    throw error;
  }
};

const deleteKeysPair = async (id) => {
  try {
    return axios.delete(`${baseURL}/company-api-keys/${id}`, {
      headers: createHeaders()
    });
  } catch (error) {
    throw error;
  }
};

const getKeyPairById = async (id) => {
  try {
    return axios.get(`${baseURL}/company-api-keys/${id}?populate=*`, {
      headers: createHeaders()
    });
  } catch (error) {
    throw error;
  }
};

const editKeyPair = async (id, data) => {
  try {
    return axios.put(`${baseURL}/company-api-keys/${id}`, data, {
      headers: createHeaders()
    });
  } catch (error) {
    throw error;
  }
};

const regenerateKeys = async (id) => {
  try {
    return axios.post(`${baseURL}/regenerate-keys`, { apiKeyId: id }, {
      headers: createHeaders()
    });
  } catch (error) {
    throw error;
  }
};

export { keysPair, deleteKeysPair, editKeyPair, getKeyPairById, regenerateKeys };