import { Box, Stack, Divider, Typography } from "@mui/material";
import { Close } from "../../assets";
import { useTheme } from "@emotion/react";

export const Modal = ({ REQUEST_DETAILS, handleModal }) => {
  const theme = useTheme();
  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          pt: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: theme.palette.textColor.primary }}
        >
          Request Details
        </Typography>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => handleModal()}
          component="img"
          src={Close}
        ></Box>
      </Stack>
      <Divider></Divider>
      <Stack sx={{ px: 10, py: 6 }} spacing={2}>
        {REQUEST_DETAILS.map((item, index) => (
          <Box key={index}>
            <Stack
              direction="row"
              key={item.label}
              sx={{ justifyContent: "space-between" }}
              spacing={4}
            >
              <Typography
                sx={{
                  flex: 1,
                  color: theme.palette.textColor.grey,
                  fontSize: "18px",
                }}
                variant="h4"
              >
                {item.label}
              </Typography>
              <Typography
                sx={{ flex: 1, fontSize: "18px" }}
                variant="h4"
              >{`: ${item.value}`}</Typography>
            </Stack>
            {index !== REQUEST_DETAILS.length - 1 ? <Divider /> : null}
          </Box>
        ))}
      </Stack>
    </>
  );
};
