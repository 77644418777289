import * as yup from "yup";
export const Validations = {
  email: yup
    .string()
    .required("Email is required.")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid Email."
    ),
  password: yup
    .string()
    .required("Password is required.")
    .min(6, "Password should contain min 6 characters"),
  name: yup.string().required("Please enter your name"),
  message: yup.string().required("Please type your message"),
  customText: yup.string().required("This field is required"),
};
