import {
  Box, Dialog, Stack, Typography,
} from '@mui/material';
import { useMemo, useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackArrow } from '../../assets';
import { Wrapper } from '../wrapper';
import { CustomTable } from '../table';
import { DataColumns } from './constant';
import { Modal } from '../modal';
import { getLogBySequenceId, viewLogs } from '../../services';
import ViewLogDialogBox from '../../pages/dashboard/viewLogDialogBox';

export const ViewLogs = () => {
  const location = useLocation();
  const { date, selectedApiId } = location.state;

  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const jwtToken = user?.jwt;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [logData, setLogData] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [sequenceLogData, setSequenceLogData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await viewLogs({
          filterDate: date,
          token: jwtToken,
          id: selectedApiId,
        });
        setLogData(res?.data || []);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchData();
  }, [date, jwtToken, selectedApiId]);

  const handleModal = () => {
    setIsDialogOpen(false);
  };

  const handleSequenceClick = async (sequenceId) => {
    setSelectedSequence(sequenceId);
    setOpen(true);
    try {
      const data = await getLogBySequenceId(sequenceId);
      setSequenceLogData(data);
    } catch (error) {
      console.error('Error fetching sequence log:', error);
    }
  };

  const convertData = (data) => {
    if (data.message === 'No data found for the specified date.') {
      return null;
    }
    return data?.map((item, index) => ({
      key: index.toString(),
      apiName: item?.apiName,
      requestTime: new Date(item?.requestTime).toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
      responseTime: item?.responseTime,
      httpStatus: item?.httpStatus,
      status: (
        <Box
          component="span"
          color={item.status === 'Fetched' ? 'green' : '#FF4A4A'}
        >
          {item.status}
        </Box>
      ),
      sequenceID: item?.requestId,
      creditUtilized: item?.totalConsumedCredit,
       
    }));
  };

  const data = useMemo(() => convertData(logData), [logData]);

  const REQUEST_DETAILS = useMemo(
    () => [
      {
        label: 'API',
        value: 'Aadhaar Masking',
      },
      {
        label: 'ENV',
        value: 'Production',
      },
      {
        label: 'HTTP Status',
        value: '200',
      },
      {
        label: 'Internal Status',
        value: '...',
      },
      {
        label: 'Internal status description',
        value: 'Data fetched successfully',
      },
      {
        label: 'Request ID',
        value: '...',
      },
      {
        label: 'Request time',
        value: 'Jan 17,2024',
      },
      {
        label: 'Response time',
        value: 'Jan 17,2024',
      },
    ],
    [],
  );

  return (
    <Box>
       <Wrapper sx={{ paddingTop: theme.spacing(8) }}>
      <Stack direction="row" sx={{ pt: 3, pb: 3 }} spacing={2}>
          <Box
            onClick={() => navigate('/')}
            component="img"
            sx={{ cursor: 'pointer' }}
            src={BackArrow}
          />
          <Typography
            sx={{ color: theme.palette.textColor.primary }}
            variant="h4"

          >
            Total Response:
            {' '}
            {logData.length}
          </Typography>
        </Stack>
        <CustomTable columns={DataColumns} data={data} />
      </Wrapper>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        maxWidth="lg"
      >
        <Modal REQUEST_DETAILS={REQUEST_DETAILS} handleModal={handleModal} />
      </Dialog>
      <ViewLogDialogBox sequenceLogData={sequenceLogData} open={open} setOpen={setOpen} sequenceId={selectedSequence} />
    </Box>
  );
};
