import { useTheme } from "@emotion/react";
import React, { useState, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ piechartData }) => {
  const theme = useTheme();
  
  const fetchedColor = theme.palette.chart.fetched;
  const failedColor = theme.palette.chart.failed;
  const greyTextColor = theme.palette.textColor.grey;
  const primaryTextColor = theme.palette.textColor.primary;

  const chartOptions = useMemo(() => ({
    colors: [fetchedColor, failedColor],
    chart: {
      type: "donut",
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "20px",
      formatter: (seriesName, opts) => {
        return `<span style="color:${greyTextColor};font-weight:500;font-size:18px;letter-spacing:0.5px">${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}</span>`;
      },
      itemMargin: {
        horizontal: 15,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          stroke: {
            width: 5,
            color: "white",
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    labels: ["Fetched", "Failed"],
    annotations: {
      texts: [
        {
          x: "50%",
          y: "40%",
          text: piechartData?.totalApiConsumption,
          textAnchor: "middle",
          foreColor: primaryTextColor,
          fontSize: "28px",
          fontWeight: "bold",
        },
        {
          x: "50%",
          y: "50%",
          text: "Total API's",
          textAnchor: "middle",
          foreColor: "grey",
          fontSize: "20px",
        },
        {
          x: "50%",
          y: "60%",
          text: "Usage",
          textAnchor: "middle",
          foreColor: "grey",
          fontSize: "20px",
        },
      ],
    },
    responsive: [],
  }), [piechartData, fetchedColor, failedColor, greyTextColor, primaryTextColor]);

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (piechartData) {
      setChartData({
        series: [piechartData.success, piechartData.failed],
        options: chartOptions,
      });
    }
  }, [chartOptions, piechartData]);

  return chartData ? (
    <div>
      <div style={{paddingTop: '40px',width: '350px', height: '350px'}} id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
        />
      </div>
    </div>
  ) : null;
};

export default ApexChart;


//commented code for updated ui

// import { Box, Grid, Paper, Typography } from '@mui/material';

// export const ApidetailsWidget = ({ title, fetchedCount, failedCount, number }) => {
//   const chipContainerStyle = {
//     display: 'flex',
//     flexDirection: 'row',
//     marginBottom: '5px'
//   };

//   const chipStyle = {
//     padding: '5px 5px',
//     borderRadius: '5px',
//     color: 'darkred',
//     backgroundColor: '#ffebee',
//     fontSize: '13px',
//     marginRight: '10px'
//   };

//   const greenChipStyle = {
//     ...chipStyle,
//     color: 'darkgreen',
//     backgroundColor: '#c8e6c9',
//   };

//   const headerStyle = {
//     fontSize: '15px',
//     marginBottom: '8px'
//   };

//   const numberStyle = {
//     fontSize: '25px',
//     marginLeft: 'auto',
//     paddingRight: '15px'
//   };

//   return (
//     <Grid container sx={{ flexDirection: 'column' }}>
//     <Grid item xs={12}>
//       <Paper sx={{ display: 'flex', flexDirection: 'row', padding: '8px' }} elevation={3}>
//         <Box sx={{ flex: 0.7 }}>
//           <Typography variant="h6" gutterBottom style={headerStyle}>{title}</Typography>
//           <div style={chipContainerStyle}>
//             <div style={greenChipStyle}>
//               Fetched - {fetchedCount}
//             </div>
//             <div style={chipStyle}>
//               Failed - {failedCount}
//             </div>
//           </div>
//         </Box>
//         <Box sx={{ flex: 0.3, alignItems: 'center', display: 'flex' }}>
//           <Typography style={numberStyle}>{number}</Typography>
//         </Box>
//       </Paper>
//     </Grid>
//     </Grid>
//   );
// };