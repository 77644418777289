import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  inset: 0,
  zIndex: theme.zIndex.loader,
  width: '100%',
  height: '100%',
  background: theme.palette.background.overlay,
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
}));

export function Loader() {
  return (
    <LoaderWrapper>
      <LinearProgress color="primary" />
    </LoaderWrapper>
  );
}
