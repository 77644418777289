import apiCall from "../utils/api-call/apiCall";
import { createHeaders } from "./constants";

const saveApiKeyAndToken = async (body) => {
  try {
    const headers = createHeaders();
    return await apiCall('save-keys', 'POST', body, headers);
  } catch (error) {
    throw error;
  }
};

export { saveApiKeyAndToken };