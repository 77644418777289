import React, { useState, useEffect } from 'react';
import {
  Typography, Stack, Paper, Divider, IconButton, Button, Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Wrapper, CustomTable } from '../../components';
import { DataColumns } from './constant';
import { generateApiKeyAndToken } from '../../services';
import { keysPair, deleteKeysPair, regenerateKeys } from '../../services/keysPairService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTheme } from '@emotion/react';
import EditDialogBox from './EditDialogBox';
import ApiKeyBox from './ApiKeyBox';
import DeleteDialogBox from './DeletDialogBox';
import { notification } from 'antd';

export const APIkey = () => {
  const { user } = useSelector((state) => state.auth);
  const [apiKey, setApiKey] = useState(() => sessionStorage.getItem('apiKey') || user.apiKey);
  const [apiSecret, setApiSecret] = useState(() => sessionStorage.getItem('apiSecret') || user.apiSecret);
  const [showTable, setShowTable] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const theme = useTheme();

  /** Function to fetch API data */
  const fetchApiData = async () => {
    try {
      const response = await keysPair({ id: user?.company?.id });
      const data = response.data.data.map((item, index) => ({
        id: item.id,
        serialNo: index + 1,
        name: item.attributes.apiAccessKeysName,
        api: item.attributes.apis.data.map(api => api.attributes.name).join(', '),
        apiKey: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.attributes.apiKey}
            <IconButton onClick={() => handleCopy(item.attributes.apiKey)} sx={{ cursor: 'pointer', ml: 1 }}>
              <ContentCopyIcon />
            </IconButton>
          </div>
        ),
        apiSecret: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.attributes.apiSecret}
            <IconButton onClick={() => handleCopy(item.attributes.apiSecret)} sx={{ cursor: 'pointer', ml: 1 }}>
              <ContentCopyIcon />
            </IconButton>
          </div>
        ),
        action: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => handleRegenerate(item.id)} sx={{ ml: 1, color: '#1976D2' }}>
              <RefreshIcon />
            </IconButton>
            <IconButton onClick={() => handleEdit(item)} sx={{ cursor: 'pointer', ml: 1, color: '#1976D2' }}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => openDeleteDialog(item.id)} sx={{ cursor: 'pointer', ml: 1, color: '#F18282' }}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </div>
        ),
      }));
      setShowTable(data.length > 0);
      setApiData(data);
    } catch (error) {
      console.error('Error fetching API data:', error);
    }
  };

  /** Effect to fetch API data on component mount */
  useEffect(() => {
    fetchApiData();
  }, []);

  /** Effect to update session storage for apiKey and apiSecret */
  useEffect(() => {
    sessionStorage.setItem('apiKey', apiKey);
    sessionStorage.setItem('apiSecret', apiSecret);
  }, [apiKey, apiSecret]);

  /** Function to handle API key regeneration */
  const handleRegenerate = async (id) => {
    try {
      const res = await regenerateKeys(id);
      if (res?.data) {
        const { apiKey: newApiKey, apiSecret: newApiSecret } = res.data.data;
        /** Update the apiData state with the new API key and secret */
        setApiData(prevData =>
          prevData.map(item =>
            item.id === id ? {
              ...item,
              apiKey: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {newApiKey}
                  <IconButton onClick={() => handleCopy(newApiKey)} sx={{ cursor: 'pointer', ml: 1 }}>
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              ),
              apiSecret: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {newApiSecret}
                  <IconButton onClick={() => handleCopy(newApiSecret)} sx={{ cursor: 'pointer', ml: 1 }}>
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              )
            } : item
          )
        );
      } else {
        console.error('Response data is empty');
      }
    } catch (error) {
      console.error('Error regenerating API key and secret:', error);
    }
  };

  /** Function to copy API key or API secret to clipboard */
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard:', text);
    }).catch((error) => {
      console.error('Error copying text to clipboard:', error);
    });
  };

  const handleEdit = (item) => {
    setEditData(item);
    setIsEditDialogOpen(true);
  };
  const handleCreate = (item) => {
    if (apiData.length >= 10) {
      notification.warning({
        message: "Alert!",
        description: "You have reached the maximum 10 API key pair limit.",
      });
      return;
    }
    setEditData(item);
    setIsEditDialogOpen(true);
  };

  /** Function to open delete confirmation dialog */
  const openDeleteDialog = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  /** Function to confirm deletion */
  const confirmDelete = async () => {
    await deleteKeysPair(deleteId);
    await fetchApiData();
    setDeleteDialogOpen(false);
  };

  /** Function to close delete dialog */
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  /** Function to close edit dialog */
  const handleClose = () => {
    setIsEditDialogOpen(false);
  };

  /** Function to save changes in edit dialog */
  const handleSave = async () => {
    await fetchApiData(); // Fetch updated API data after save
    setIsEditDialogOpen(false); // Close the edit dialog
  };

  return (
    <Wrapper sx={{ paddingTop: theme.spacing(4), px: { xs: 2, sm: 3, md: 4 } }}>
      {!showTable && <ApiKeyBox onSave={handleSave} />}
      {showTable && (
        <Paper sx={{ mt: 10, mx: { xs: 1, sm: 2, md: 3 } }}>
          <Stack sx={{ py: 1, px: { xs: 2, sm: 3, md: 4 } }} spacing={1}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>API Key</Typography>
              <Button
                onClick={() => {
                  handleCreate(null);
                }}
                color="primary"
                variant="contained"
                sx={{ fontSize:{sx:'1.5rem'} , ml: 2 }}
              >
                Create API Key
              </Button>
            </Box>
            <Divider />
            <CustomTable customClass={false} columns={DataColumns} data={apiData} />
          </Stack>
        </Paper>
      )}
      {isEditDialogOpen && (
        <EditDialogBox open={isEditDialogOpen} handleClose={handleClose} handleSave={handleSave} editData={editData} />
      )}
      <DeleteDialogBox open={deleteDialogOpen} onClose={handleDeleteDialogClose} onConfirm={confirmDelete} />
    </Wrapper>
  );
};
