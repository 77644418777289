import React, { useState, useEffect } from 'react';
import { Typography, Stack, Paper, Divider, Button, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { ApiKey } from '../../assets';
import ApiKeyDialog from './ApiKeyDialog';
import { apiCategortList, generateApiKeyAndToken } from '../../services';
import { creditsPerApi } from '../../services/creditsService';
import { saveApiKeyAndToken } from '../../services/saveKeysService';

const ApiKeyBox = ({ onSave }) => {
  const [open, setOpen] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [apiList, setApiListData] = useState([]);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      await handleRegenerate();
      await fetchCreditsPerApi();
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const handleRegenerate = async () => {
    try {
      const res = await generateApiKeyAndToken();
      if (res?.data) {
        const { apiKey: newApiKey, apiSecret: newApiSecret } = res?.data?.data;
        setApiKey(newApiKey);
        setApiSecret(newApiSecret);
      } else {
        console.error('Response data is empty');
      }
    } catch (error) {
      console.error('Error regenerating API key and secret:', error);
    }
  };

  const fetchCreditsPerApi = async () => {
    try {
      const response = await apiCategortList();
      const apisByCategory = response.data?.data?.attributes?.apisByCategory;
      if (apisByCategory) {        
        const apiList = [];
        
        Object.values(apisByCategory).forEach(category => {
            category.apis.forEach(api => {
                apiList.push({
                    key: api.id,
                    apiName: api.name,
                });
            });
        });
        
        setApiListData(apiList);
    }
    } catch (error) {
      console.error('Error fetching credits per API:', error);
    }
  };

  const handleSave = async (apiAccessKeysName, selectedApi) => {
    try {
      const saveRes = await saveApiKeyAndToken({
        apiKey,
        apiSecret,
        apiAccessKeysName,
        apis: selectedApi,
      });

      if (saveRes.status === 200) {
        console.log('API key and token saved successfully');
      } else {
        console.error('Failed to save API key and token');
      }
    } catch (error) {
      console.error('Error saving API key and token:', error);
    }
    setOpen(false);
    onSave();
  };

  const handleClickOpen = async () => {
    await handleRegenerate();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper sx={{ paddingTop: 1, paddingBottom: 5, mt: 8 }}>
      <Stack spacing={1}>
        <Typography variant="h4" p={1}>
          API Keys
        </Typography>
        <Divider />
        <Box
          component="img"
          sx={{
            width: {xs:'50%',sm:'30%',md:'25%'},
            alignSelf: 'center',
            marginTop: '30px !important',
          }}
          src={ApiKey}
        />
        <Typography
          sx={{
            textAlign: 'center',
            width:{md:'30%'},
            margin:{xs:'20px 22px !important',md:'20px auto !important',}
          }}
          fontSize="17px"
        >
          Please generate an API key pair to access the APIs.
        </Typography>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Button
            variant="contained"
            sx={{ textAlign: 'center' }}
            onClick={handleClickOpen}
          >
            Create API Key
          </Button>
        </Box>
      </Stack>
      <ApiKeyDialog
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        apiKey={apiKey}
        apiSecret={apiSecret}
        apiList={apiList}
      />
    </Paper>
  );
};

export default ApiKeyBox;
