import { Box, Divider, Typography } from '@mui/material';
import BasicTable from './BasicTable';

const DescriptionBox = ({
  title, description, refProp, showBasicTable = 'false', columns, rows,
}) => {
  return (
    <Box
      ref={refProp}
      sx={{
        background: '#ffffff',
      }}
    >
      <Typography
        pl={4}
        color="#1565C0"
        sx={{ fontSize: '24px', fontWeight: '500' }}
      >
        {title}
      </Typography>
      <Divider variant="middle" sx={{ marginLeft: '32px' }} />
      <Box
        sx={{
          backgroundColor: '#ffffff',
          padding: '16px 32px',
        }}
      >
        <Typography sx={{ fontSize: '16px' }}>
          {description}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin="10px 0"
      >
        <Box width="80%">
          {showBasicTable && columns && rows ? <BasicTable columns={columns} rows={rows} />: null}
        </Box>
      </Box>
    </Box>
  );
};

export default DescriptionBox;
