import axios from "axios";
import { baseURL, createHeaders } from "./constants";

const changePassword = async (payload) => {
  return axios.post(
    `${baseURL}/auth/change-password`,
    {
      ...payload,
    },
    {
      headers: createHeaders(),
    }
  );
};

const editPersonalDetails = async ({userId, payload}) => {
  return axios.put(
    `${baseURL}/users/${userId}`,
    {
      ...payload,
    },
    {
      headers: createHeaders(),
    }
  );
};

const getPersonalDetails = async ({userId}) => {
  return axios.get(
    `${baseURL}/users/${userId}`,
    {
      headers: createHeaders(),
    }
  );
};

const getRoles = async () => {
  return axios.get(
    `${baseURL}/users-permissions/roles`,
    {
      headers: createHeaders(),
    }
  );
};

const createUser = async (payload) => {
  return axios.post(
    `${baseURL}/auth/local/register`,
    {
      ...payload,
    },
    {
      headers: createHeaders(),
    }
  );
};

const editUser = async (id, payload) => {
  return axios.put(
    `${baseURL}/users/${id}`,
    {
      ...payload,
    },
    {
      headers: createHeaders(),
    }
  );
};

export { changePassword, editPersonalDetails, getPersonalDetails, getRoles, editUser, createUser };
