const hostname = window.location.hostname;
export const baseURL =  
(hostname === "localhost" || hostname === "finhub-stage.habilelabs.io")
 ? "https://backend-stage.finhub.habilelabs.io/api" : "https://backend.finhub.habilelabs.io/api";
  
export const createHeaders = () => {
  const token = sessionStorage.getItem("authToken");
  return {
    Authorization: "Bearer " + token,
  };
};

export const onscreenTestHeaders = () => {
  let apiKey = sessionStorage.getItem("apiKey");
  let apiSecret = sessionStorage.getItem("apiSecret");

  if (!apiKey || !apiSecret) {
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userDetails) {
      apiKey = userDetails.apiKey;
      apiSecret = userDetails.apiSecret;
    }
  }

  return {
    api_key: apiKey,
    api_secret: apiSecret,
  };
};

