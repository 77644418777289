import React, { useState, useEffect } from "react";
import { Dialog, Button } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PdfViewer = ({
  open,
  onClose,
  previewImage,
  useDialog,
  isPdf,
  setPageNumber,
  pageNumber,
}) => {
  const [fileType, setFileType] = useState("");

  const [numPages, setNumPages] = useState(null);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getFileType = (url) => {
    if (url?.endsWith(".pdf")) {
      return "pdf";
    } else if (
      url?.endsWith(".jpg") ||
      url?.endsWith(".jpeg") ||
      url?.endsWith(".png")
    ) {
      return "image";
    } else {
      return "";
    }
  };

  // Set the file type when the component mounts
  useState(() => {
    setFileType(getFileType(previewImage));
  }, [previewImage]);

  const renderFilePreview = () => {
    if (fileType === "pdf") {
      return (
        <embed
          src={previewImage}
          type="application/pdf"
          width="100%"
          height="500px"
        />
      );
    } else if (fileType === "image") {
      return (
        <img
          src={previewImage}
          alt="File preview"
          style={{ maxWidth: "100%", maxHeight: "600px" }}
        />
      );
    }
  };

  if (useDialog) {
    return (
      <Dialog open={open} onClose={onClose}>
        {isPdf ? (
          <div>
            <div style={{ height: "500px", width: "700px", overflow: "auto" }}>
              <Document
                file={previewImage}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "5px 0px 5px 0px",
                gap: "5px",
              }}
            >
              <Button
                onClick={previousPage}
                disabled={pageNumber <= 1}
                variant="contained"
                color="primary"
              >
                Previous
              </Button>
              <Button
                onClick={nextPage}
                disabled={pageNumber >= numPages}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </div>
          </div>
        ) : (
          <img alt="file" src={previewImage} />
        )}
      </Dialog>
    );
  } else {
    return <div>{renderFilePreview()}</div>;
  }
};

export default PdfViewer;
