export const DataColumns = [
  {
    title: "S.No.",
    dataIndex: "serialNo",
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (text) => (
      <div style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
          width:'230px',    
        textAlign:'left'
      }}>
        {text}
      </div>
    ),
  },
  {
    title: "API Key",
    dataIndex: "apiKey",
  },
  {
    title: "API Secret Key",
    dataIndex: "apiSecret",
    render: (text) => (
      <div style={{
        textAlign:'right',
      }}>
        {text}
      </div>
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
