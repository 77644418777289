import { Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { downloadToken } from "../../services";

export const DownloadToken = ({ token, documentName }) => {
  const handleDownload = async () => {
    try {
      const response = await downloadToken(token);

      // Get the content type from the response headers
      const contentType = response.headers["content-type"];
      // let fileExtension = "";

      // // Determine the file extension based on the content type
      // switch (contentType) {
      //   case "image/jpeg":
      //     fileExtension = "jpeg";
      //     break;
      //   case "image/png":
      //     fileExtension = "png";
      //     break;
      //   case "application/pdf":
      //     fileExtension = "pdf";
      //     break;
      //   default:
      //     fileExtension = "jpg"; // Default to jpg if the content type is not recognized
      //     break;
      // }

      const url = URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${documentName}`); // Use the determined file extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <>
      <Box component="div" onClick={handleDownload}>
        <DownloadIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
      </Box>
    </>
  );
};
