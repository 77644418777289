import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Typography, Box, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AddToVaultDialog = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Add API Response to Vault</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ marginTop: 0 }} />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h5">Are you sure you want to add this API response to the vault?</Typography>
      </Box>
      <Divider sx={{ marginTop: 0 }} />
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined" sx={{ padding: '0px 8px', minWidth: '64px' }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained" sx={{ padding: '2px 11px', minWidth: '64px' }} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToVaultDialog;
