import React from "react";
import ReactJson from "react-json-view";

export const JsonViewer = ({ data }) => {
  return (
    <ReactJson
      src={data}
      theme="monokai"
      name={null}
      displayDataTypes={false}
      displayObjectSize={false}
      style={{ backgroundColor: "#263238", padding: "10px" }}
      enableClipboard={false}
      indentWidth={2}
    />
  );
};
