import apiCall from "../utils/api-call/apiCall";
import { createHeaders } from "./constants";

const billData = async (params) => {
  const { id } = params;
  try {
    const data = {
      populate: '*',
      filters: {
        company: {
          id: id
        }
      }
    };
    return await apiCall(`assign-credits`, 'GET', data, createHeaders());
  } catch (error) {
    throw error;
  }
};

export { billData };
