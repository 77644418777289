import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { PersonalDetails } from "../../assets";
import { editPersonalDetails, getPersonalDetails } from "../../services";
import { notification } from "antd";
import { ErrorText } from "../../components";

const PersonalDetail = () => {
  const theme = useTheme();
  const user = JSON.parse(sessionStorage.getItem("userDetails"));
  
  const userId = user?.id;

  const [editMode, setEditMode] = useState(false);
  const [formValues, setFormValues] = useState({
    username: "",
    email: "",
    contactNumber: "",
  });
  const [initialValues, setInitialValues] = useState({
    username: "",
    email: "",
    contactNumber: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPersonalDetails({ userId });
        const data = res?.data;
        setFormValues({
          username: data.username,
          email: data.email,
          contactNumber: data.contactNumber,
        });
        setInitialValues({
          username: data.username,
          email: data.email,
          contactNumber: data.contactNumber,
        });
      } catch (error) {
        console.error('Failed to fetch personal details', error);
      }
    };
    
    fetchData();
  }, [userId]);

  const handleEditClick = () => setEditMode(true);

  const validateField = (name, value) => {
    let error = "";
    if (name === "username") {
      if (!value.trim()) {
        error = "Username cannot be empty.";
      } else if (value.length < 3) {
        error = "Username must be at least 3 characters long.";
      }
    } else if (name === "contactNumber") {
      if (!value.trim()) {
        error = "Contact number cannot be empty.";
      } else if (!/^\d*$/.test(value)) {
        error = "Contact number should contain only numbers.";
      } else if (value.length !== 10) {
        error = "Contact number must be 10 digits.";
      }
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "contactNumber" && (!/^\d*$/.test(value) || value.length > 10)) {
      return;
    }

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    const { username, contactNumber } = formValues;
    const usernameError = validateField("username", username);
    const contactNumberError = validateField("contactNumber", contactNumber);

    if (usernameError || contactNumberError) {
      setErrors({
        username: usernameError,
        contactNumber: contactNumberError,
      });
      return;
    }

    try {
      await editPersonalDetails({
        userId,
        payload: { username, contactNumber },
      });

      setInitialValues({ ...formValues });

      notification.success({
        message: 'Personal Details Update',
        description: 'Your details have been successfully updated',
      });

      setEditMode(false);
    } catch (error) {
      console.error('Failed to save personal details', error);
    }
  };

  const handleCancelClick = () => {
    setFormValues({ ...initialValues });
    setErrors({});
    setEditMode(false);
  };

  return (
    <Container sx={{ position: "relative", right: "15px" }}>
      <Stack spacing={4} direction="row" marginTop={1}>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            {["email", "username", "contactNumber"].map((key) => (
              <Stack key={key} direction="row" alignItems="center">
                <Typography
                  sx={{
                    flex: 1,
                    wordBreak: "break-all",
                    color: theme.palette.grey.darken4,
                    fontSize: "1.8rem",
                  }}
                  variant="h4"
                >
                  {key === "username" ? "Name" : key === "email" ? "Email Id" : "Contact No."}
                </Typography>
                {editMode && key !== "email" ? (
                  <Stack sx={{ flex: 1 }}>
                    <TextField
                      sx={{
                        wordBreak: "break-all",
                        fontSize: "1.8rem",
                      }}
                      variant="outlined"
                      name={key}
                      value={formValues[key]}
                      onChange={handleInputChange}
                      error={!!errors[key]}
                    />
                    {errors[key] && <ErrorText>{errors[key]}</ErrorText>}
                  </Stack>
                ) : (
                  <Typography
                    sx={{
                      flex: 1,
                      wordBreak: "break-all",
                      color: theme.palette.grey.darken4,
                      fontSize: "1.8rem",
                    }}
                    variant="h4"
                  >
                    : {formValues[key] || "N/A"}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
          {editMode ? (
            <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
              <Button variant="outlined" color="primary" onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSaveClick}>
                Save
              </Button>
            </Stack>
          ) : (
            <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleEditClick}>
              Edit Details
            </Button>
          )}
        </Box>
        <Box component="img" sx={{ maxWidth:'38%' }} src={PersonalDetails} />
      </Stack>
    </Container>
  );
};

export default PersonalDetail;
