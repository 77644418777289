export const DataColumns = [
  {
    title: "Date",
    dataIndex: "date",
    defaultSortOrder: "descend",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
  },
  {
    title: "API's Used",
    dataIndex: "apiUsed",
  },
  {
    title: "Total Request",
    dataIndex: "totalRequest",
  },
  {
    title: "Success Count",
    dataIndex: "successCount",
  },
  {
    title: "Failure Count",
    dataIndex: "failureCount",
  },
  {
    title: "Credit Utilized",
    dataIndex: "creditsUtilized",
  },
  {
    title: "Usage",
    dataIndex: "usage",
  },
];
