import { Box, styled } from "@mui/material";

const BoxStyled = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  height: "100%",
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up("xl")]: {
    width: theme.breakpoints.values["xl"] + (theme.breakpoints.unit || "px"),
  },
}));

export const Wrapper = ({ children, sx, ...props }) => {
  return (
    <BoxStyled sx={sx} {...props}>
      {children}
    </BoxStyled>
  );
};
