import { Box } from "@mui/material";

export const ActiveButton = () => {
  return (
    <Box 
      sx={{
        borderRadius: 5,
        color: "#008234",
        background: "#BBFFA3",
        width: "58px",
        padding: 0.3,
        fontSize: 12,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      Active
    </Box>
  );
};
