import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLogged: JSON.parse(sessionStorage.getItem("isLogged")),
    user: JSON.parse(sessionStorage.getItem("userDetails")) || {},
    credits: 0,
  },
  reducers: {
    loginUser: (state, { payload }) => {
      const { user, jwt } = payload;
      state.isLogged = true;
      state.user = {
        ...user?.company,
        company: user?.company,
        role: user?.role,
        username: user?.username,
        id: user?.id,
        contactNumber: user?.contactNumber,
        email: user?.email,
        jwt: jwt,
        
      };
      sessionStorage.setItem("isLogged", true);
      sessionStorage.setItem("authToken", jwt);
      sessionStorage.setItem("userDetails", JSON.stringify(state.user));
    },
    logoutUser: (state, { payload }) => {
      state.isLogged = false;
      state.user = null;
      sessionStorage.clear();
    },
    updateTokenAndKey: (state, { payload }) => {
      const { apiKey, apiSecret } = payload;
      state.user = { ...state.user, apiKey, apiSecret };
      sessionStorage.setItem("userDetails", JSON.stringify(state.user));
    },
    remainingCredits: (state, { payload }) => {
      const { credits } = payload;
      console.log('credits :', credits);
      state.user = { ...state?.user, currentCredits: credits };
      state.credits = credits;
      sessionStorage.setItem("userDetails", JSON.stringify(state?.user));
    },
  },
});
export const { loginUser, logoutUser, updateTokenAndKey, updateCredits, remainingCredits } =
  authSlice.actions;
export const authReducer = authSlice.reducer;
