import { useTheme } from "@emotion/react";
import { Divider } from "@mui/material";

export const CustomDivider = ({ dividerColor }) => {
  const theme = useTheme();
  return (
    <Divider
      sx={{
        mt: "0px",
        mb: "0px",
        borderColor: dividerColor || theme.palette.divider,
      }}
      orientation="vertical"
      variant="middle"
      flexItem
    />
  );
};
