import apiCall from '../utils/api-call/apiCall';
import { createHeaders } from './constants';

const getUsersData = async () => {
  try {
    const headers = createHeaders();
    return await apiCall('find-company-users', 'GET', null, headers);
  } catch (error) {
    throw error;
  }
};

export { getUsersData };
