import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Dialog,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { changePasswordSchema } from './validations';
import { Logs, changePassword } from '../../services';
import { ErrorText } from '../../components';
import { Check, ChangePassword } from '../../assets';
import { logoutUser } from '../../store/reducers/auth';
import { notification } from 'antd';

export const ResetPassword = () => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: 'all',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    control,
  } = form;

  const handleChangePassword = async (payload) => {
    try {
      const res = await changePassword(payload);
      if (res.status === 200) {
        setInvalidCredentials('');
        setShowSuccess(true);
        notification.success({
          message: "Password Updated!",
          description: "Password has been changed Successfully use your new password to login."
        })
        navigate('/login');
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error.message;
      setInvalidCredentials(errorMessage);
      if (errorMessage === 'Passwords do not match') {
        setError('passwordConfirmation', {
          type: 'manual',
          message: errorMessage,
        });
      }
      Logs(error);
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  // Watch the password fields to clear errors dynamically
  const newPassword = useWatch({ control, name: 'password' });
  const confirmPassword = useWatch({ control, name: 'passwordConfirmation' });

  useEffect(() => {
    if (newPassword && confirmPassword && newPassword === confirmPassword) {
      clearErrors('passwordConfirmation');
      setInvalidCredentials('');
    }
  }, [newPassword, confirmPassword, clearErrors]);

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(handleChangePassword)}
        sx={{ width: '100%', position: 'relative', display:'flex', justifyContent:'space-between', alignItems:'flex-end' }}
      >
        <Stack spacing={5} sx={{ width: '45%' }}>
          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Current Password
            </Typography>
            <TextField
              sx={{
                background: theme.palette.background.main,
                height: '4rem',
                borderRadius: '0.8rem',
              }}
              fullWidth
              id="currentPassword"
              type={showPassword.current ? 'text' : 'password'}
              {...register('currentPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordVisibility('current')}
                      edge="end"
                    >
                      {showPassword.current ? (
                        <VisibilityIcon sx={{ fontSize: 18 }} />
                      ) : (
                        <VisibilityOffIcon sx={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!errors.currentPassword && (
              <ErrorText>{errors.currentPassword.message}</ErrorText>
            )}
          </Box>
          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>
              New Password
            </Typography>
            <TextField
              sx={{
                background: theme.palette.background.main,
                height: '4rem',
                borderRadius: '0.8rem',
              }}
              fullWidth
              id="password"
              type={showPassword.new ? 'text' : 'password'}
              {...register('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordVisibility('new')}
                      edge="end"
                    >
                      {showPassword.new ? (
                        <VisibilityIcon sx={{ fontSize: 18 }} />
                      ) : (
                        <VisibilityOffIcon sx={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!errors.password && (
              <ErrorText>{errors.password.message}</ErrorText>
            )}
          </Box>

          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Confirm Password
            </Typography>
            <TextField
              sx={{
                background: theme.palette.background.main,
                height: '4rem',
                borderRadius: '0.8rem',
              }}
              fullWidth
              id="passwordConfirmation"
              type={showPassword.confirm ? 'text' : 'password'}
              {...register('passwordConfirmation')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordVisibility('confirm')}
                      edge="end"
                    >
                      {showPassword.confirm ? (
                        <VisibilityIcon sx={{ fontSize: 18 }} />
                      ) : (
                        <VisibilityOffIcon sx={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!errors.passwordConfirmation && (
              <ErrorText>{errors.passwordConfirmation.message}</ErrorText>
            )}
            {invalidCredentials && !errors.passwordConfirmation && (
              <ErrorText>{invalidCredentials}</ErrorText>
            )}
          </Box>
          <Button
            sx={{ width: 'fit-content', marginLeft: 2 }}
            variant="contained"
            type="submit"
          >
            Change Password
          </Button>
        </Stack>
        <Box
          component="img"
          sx={{ maxWidth:'40%' }}
          src={ChangePassword}
        />
      </Box>
    </>
  );
};
