import axios from "axios";
import { baseURL } from "./constants";

const loginApi = async (email, password) => {
  try {
    return axios.post(`${baseURL}/auth/local`, { email: email, password });
  } catch (error) {
    throw error;
  }
};

const forgotPasswordApi = async (email) => {
  try {
    return axios.post(`${baseURL}/auth/forgot-password`, { email });
  } catch (error) {
    throw error;
  }
};

const resetPasswordApi = async ({ newPassword, confirmPassword, code }) => {
  try {
    return axios.post(`${baseURL}/auth/reset-password`, {
      password: newPassword,
      passwordConfirmation: confirmPassword,
      code,
    });
  } catch (error) {
    throw error;
  }
};

const resetPasswordCodeCheck = async (code) => {
  try {
    return axios.get(`${baseURL}/auth/code-link-valid`, {
      params: {
        code: code
      }
    });
  } catch (error) {
    throw error;
  }
};




export { loginApi, forgotPasswordApi, resetPasswordApi, resetPasswordCodeCheck };
