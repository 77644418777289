import { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomTable, Wrapper } from '../../components';
import { DataColumns } from './constant';
import { activePlansSummary, creditsPerApi, totalCreditsAndRemaining } from '../../services/creditsService';
import CreditCircularProgress from '../../charts/semicircle-charts/StrokeGauge';
import CustomCarousel from '../../components/customSplide/CustomSplide';
import { RequestCreditComponent } from '../../components/requestCredit/RequestCredit';
import { apiCategortList } from '../../services';

export function Credit() {
  const [creditsPerApiData, setCreditsPerApiData] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [res, setRes] = useState();
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((state) => state.auth);

  const companyId = user.company.id;
  const jwtToken = user.jwt;

  useEffect(() => {
    const fetchActivePLan = async () => {
      try {
        const res = await activePlansSummary({ id: companyId });
        setRes(res);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchActivePLan();
  }, [companyId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await totalCreditsAndRemaining(jwtToken);
        setTotalCredits(res?.data?.credit?.totalAssignedCredit);
        setRemainingCredits(res?.data?.credit?.remainingCredit);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [jwtToken]);

  useEffect(() => {
    const fetchCreditsPerApi = async () => {
        try {
            const response = await apiCategortList();        
            const apisByCategory = response.data?.data?.attributes?.apisByCategory;
            
            if (apisByCategory) {
                let globalIndex = 0;
                
                const creditsPerApiData = [];
                
                Object.values(apisByCategory).forEach(category => {
                    category.apis.forEach(api => {
                        globalIndex++;
                        creditsPerApiData.push({
                            serialNo: globalIndex.toString(),
                            key: api.id,
                            apisUsed: api.name,
                            creditUtilised: api.creditConsumptionValue,
                        });
                    });
                });
                
                setCreditsPerApiData(creditsPerApiData);
            }
        } catch (error) {
            console.error('Error fetching credits per API:', error);
        }
    };

    fetchCreditsPerApi();
}, [companyId]);


  return (
    <Wrapper>
      <Typography variant="h3" mt={10} sx={{ fontWeight: 500 }}>
        Current Credit Summary
      </Typography>
      <Grid container spacing={3} sx={{ pt: 4 }}>
        <Grid item xs={12} md={6}>
          {!loading && (
            <CustomCarousel res={res} />
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {!loading && (
            <CreditCircularProgress
              remainingCredits={remainingCredits}
              totalCredits={totalCredits}
            />
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <RequestCreditComponent />
        </Grid>
      </Grid>
      <Typography sx={{ py: 3 }} variant="h3">
        API Credit Usage
      </Typography>
      <CustomTable columns={DataColumns} data={creditsPerApiData} />
    </Wrapper>
  );
}
