import React from 'react';
import { useTheme } from '@emotion/react';
import { Box, Paper, Stack, Typography, IconButton, Divider } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import { CompanyName } from '../../assets';

export const MobileCompany = ({ onClose }) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const PROFILE_KEYS = useMemo(() => {
    if (user && Object.keys(user).length !== 0) {
      return [
        { label: 'Company Name', value: user?.company?.name },
        { label: 'Email Id', value: user?.company?.email },
        { label: 'Contact No.', value: user?.company?.contactNumber },
        { label: 'GSTN', value: user?.company?.GSTN },
        { label: 'PAN', value: user?.company?.PAN },
        { label: 'TAN', value: user?.company?.TAN },
        { label: 'Address', value: user?.company?.address },
      ];
    }
    return [];
  }, [user]);

  return user ? (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
          padding:'0 16px',
          boxShadow: 'none',
        position: 'relative',
      }}
    >

      <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <BusinessIcon sx={{ fontSize: '2rem', mr: 1 }} />
        <Typography variant="h6" component="div" sx={{ flex: 1 }}>
          Company Profile
        </Typography>
        <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
          <CloseIcon/>
        </IconButton>
      </Box>
     <Divider sx={{mt:0, mb:2}} />
      <Stack spacing={2} sx={{ flex: 1 }}>
        {PROFILE_KEYS.map((item) => (
          <Stack key={item.label} direction="row">
            <Box sx={{width:'50%'}}> 
            <Typography
              variant="body1"
            >
              {item.label}
            </Typography>
            </Box>
            <Box sx={{width:'50%'}}>
            <Typography
              sx={{
                wordWrap: "break-word"
              }}
              variant="body1"
              
            >
              :&nbsp;{`${item.value}`}
            </Typography>
            </Box>
           
          </Stack>
        ))}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Box component="img" sx={{ maxWidth: '100%' }} src={CompanyName} />
      </Box>
    </Paper>
  ) : null;
};

export default MobileCompany;
