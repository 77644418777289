import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';
import { Loader } from '../loader';
import PdfViewer from '../../utils/pdfViewer/PdfViewer';

export function ApiResponse({ loader, response, errorObject }) {
  const theme = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  let content = null;
  const isPdf = response?.image?.masked && (response.image.masked.endsWith('.pdf') || response.image.masked.endsWith('.PDF')) && response.image.masked.startsWith('http');

  if (isPdf) {
    content = (
      <PdfViewer
        open
        onClose={() => {}}
        isPdf={isPdf}
        previewImage={response?.image?.masked}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        useDialog={false}
      />
    );
  } else if (response?.image?.masked) {
    content = (
      <Box
        sx={{ width: '100%', height: '100%' }}
        component="img"
        src={response?.image?.masked}
      />
    );
  } else if (response?.image?.error) {
    content = (
      <Box sx={{ px: 2, pt: 3 }}>
        <Typography>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              background: '#f0f0f0',
              padding: '10px',
              minHeight: '40rem',
            }}
          >
            {JSON.stringify(response?.image?.error, null, 2)}
          </pre>
        </Typography>
      </Box>
    );
  }  else if (response?.text) {
    const updatedResponse = {
      ...response.text,
      detectedFiles: response?.text?.detectedFiles?.map(file =>
        file.replace(/\n/g, "")
      )
    };
  
    content = (
      <Box sx={{ px: 2, pt: 3 }}>
        <Typography>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              background: '#f0f0f0',
              padding: '10px',
              minHeight: '40rem',
              overflowX: "auto"
            }}
          >
            {JSON.stringify(updatedResponse, null, 2)}
          </pre>
        </Typography>
      </Box>
    );
  }

  const status = loader ? null : response?.status;

  const statusColor = status === 200 ? 'green' : 'red';

  return (
    <Stack>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            mt: 2,
            mb: 2,
            color: theme.palette.textColor.primary,
          }}
          variant="h5"
        >
          Status:
        </Typography>
        <Typography
          sx={{
            color: statusColor,
            px: 1,
          }}
          variant="h5"
        >
          {loader ? <Loader /> : status}
        </Typography>
      </Box>
      <Box
        sx={{
          background: theme.palette.background.main,
          width: '100%',
          height: '100%',
          minHeight: '40rem',
        }}
      >
        {content}
      </Box>
    </Stack>
  );
}

export default ApiResponse;
