export const DataColumns = [
  {
    title: "S.No.",
    dataIndex: "serialNo",
  },
  {
    title: "APIs",
    dataIndex: "apisUsed",
  },
  {
    title: "Credits",
    dataIndex: "creditUtilised",
  },
];

export const Data = [
  {
    serialNo: "1",
    key: 1,
    apisUsed: "Invoice OCR",
    creditUtilised: 60,
  },
  {
    serialNo: "2",
    key: 2,
    apisUsed: "Pan OCR",
    creditUtilised: 50,
  },
  {
    serialNo: "3",
    key: 3,
    apisUsed: "Aadhaar OCR",
    creditUtilised: 70,
  },
  {
    serialNo: "4",
    key: 4,
    apisUsed: "Aadhaar Masking",
    creditUtilised: 80,
  },
  {
    serialNo: "5",
    key: 5,
    apisUsed: "Pan OCR",
    creditUtilised: 65,
  },
  {
    serialNo: "6",
    key: 6,
    apisUsed: "Invoice OCR",
    creditUtilised: 75,
  },
  {
    serialNo: "7",
    key: 7,
    apisUsed: "Aadhaar Masking",
    creditUtilised: 85,
  },
  {
    serialNo: "8",
    key: 8,
    apisUsed: "Aadhaar OCR",
    creditUtilised: 55,
  },
  {
    serialNo: "9",
    key: 9,
    apisUsed: "Pan OCR",
    creditUtilised: 90,
  },
  {
    serialNo: "10",
    key: 10,
    apisUsed: "Invoice OCR",
    creditUtilised: 72,
  },
];
