const typography = {
  fontFamily: ["DM sans"],
  fontWeight: 700,
  h1: {
    fontWeight: 500,
  },
  h2: {
    fontSize: "2.8rem",
    fontWeight: 500,
    lineHeight: "2.4rem",
    letterSpacing: "0.5px",
  },
  h3: {
    fontSize: "2.5rem",
    fontWeight: 400,
    lineHeight: "3.2rem",
    letterSpacing: "0.5px",
  },
  h4: {
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "2.4rem",
    letterSpacing: "0.5px",
  },
  h5: {
    fontWeight: 700,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    letterSpacing: "0.5px",
  },
  h6: {
    fontWeight: 500,
  },
  body1: {
    fontWeight: 500,
    lineHeight: "1.8",
    fontSize: "1.4rem",
    letterSpacing: "0.5px",
  },
};

export default typography;
