import apiCall from '../utils/api-call/apiCall';
import { createHeaders } from './constants';

const apiList = async () => {
  try {
    const params = {
      populate: 'apiCategory',
    };
    return await apiCall('api-details', 'GET', params);
  } catch (error) {
    throw error;
  }
};
const apiCategortList = async () => {
  try {
    return await apiCall('get-company-apis', 'GET', null,  createHeaders());
  } catch (error) {
    throw error;
  }
};

const dashboardCharts = async (id, dashboardDates) => {
  console.log('dashboardDates :', dashboardDates);
  try {
    const params = {
      apiId: id,
      startDate: dashboardDates?.startDate,
      endDate: dashboardDates?.endDate,
    };
    return await apiCall('api-consumption-dashboard', 'GET', params, createHeaders());
  } catch (error) {
    throw error;
  }
};

const usageData = async () => {
  try {
    const params = {
      apiId: 1,
      startDate: '2024-03-15',
      endDate: '2024-03-20',
    };
    return await apiCall('api-uses-list', 'GET', params);
  } catch (error) {
    throw error;
  }
};

const viewLogs = async (params) => {
  const { filterDate, token, id } = params;
  try {
    const queryParams = {
      filterDate,
      apiId: id,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await apiCall('api-logs', 'GET', queryParams, headers);
  } catch (error) {
    throw error;
  }
};

const usageLogs = async (params) => {
  const {
    apiId, token, startDate, endDate,
  } = params;
  try {
    const queryParams = {
      apiId,
      startDate,
      endDate,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await apiCall('api-uses-list', 'GET', queryParams, headers);
  } catch (error) {
    throw error;
  }
};

const downloadLogs = async (id, dashboardDates) => {
  try {
    const params = {
      apiId: id,
      startDate: dashboardDates.startDate,
      endDate: dashboardDates.endDate,
    };
    const responseType = 'blob';
    return await apiCall('export-api-log', 'GET', params, createHeaders(), responseType);
  } catch (error) {
    throw error;
  }
};

const getLogBySequenceId = async (requestId) => {
  try {
    const url = `api-logs/${requestId}`;
    return await apiCall(url, 'GET', null, createHeaders());
  } catch (error) {
    throw error;
  }
};

export {
  apiList, dashboardCharts, usageData, viewLogs, apiCategortList, downloadLogs, usageLogs,getLogBySequenceId
};
