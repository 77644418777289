import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const GuestGuard = (props) => {
  const { requiredRole } = props;
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role.toLowerCase() !== requiredRole?.toLowerCase())
      navigate("/", { replace: true });
  }, [user, navigate, requiredRole]);
  return props.children;
};
