import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Select,
  Switch,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Import visibility icons
import { useForm, Controller } from "react-hook-form";
import { createUser, editUser, getRoles } from "../../services";
import { notification } from "antd";
import { ErrorText } from "../../components";
import CloseIcon from '@mui/icons-material/Close';

export const CreateUser = ({ drawerClose, fetchData, user }) => {
  const { handleSubmit, control, formState: { errors }, reset } = useForm({
    defaultValues: {
      username: '',
      role: '',
      contactNumber: '',
      email: '',
      password: '',
      confirmed: true,
      blocked: true,
    },
  });

  const [roles, setRoles] = useState([]);
  const [roleMap, setRoleMap] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const company = userDetails?.company?.id;

  const handleClose = () => {
    drawerClose(false);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getRoles();
        const rolesData = response?.data?.roles || [];
        setRoles(rolesData);
        
        const map = {};
        rolesData.forEach(role => {
          map[role.name] = role.id;
        });
        setRoleMap(map);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    if (user) {
      const userRoleId = roleMap[user.role] || '';
      const userData = {
        ...user,
        confirmed: true,
        role: userRoleId,
        contactNumber: user.contactNumber || '',
        blocked: user.blocked === 'Active',
      };
      reset(userData);
    }
  }, [user, roleMap, reset]);

  const onSubmit = async (data) => {
    const roleId = roles.find(role => role.id === Number(data.role))?.id || '';

    const formData = {
      ...data,
      company,
      confirmed: true,
      role: roleId,
      blocked: !data.blocked,
    };
    console.log('formData :', formData);
    try {
      if (user) {
        const { id, ...dataWithoutId } = formData;
        const response = await editUser(user.id, dataWithoutId);
        if (response.status === 200) {
          fetchData();
          drawerClose(false);
          notification.success({
            message: 'User Updated',
            description: 'The user details have been successfully updated.',
          });
        }
      } else {
        const response = await createUser(formData);
        if (response.status === 200) {
          fetchData();
          drawerClose(false);
          notification.success({
            message: 'User Created',
            description: 'The user has been successfully created.',
          });
        }
      }
    } catch (error) {
      console.error(user ? 'Error editing user:' : 'Error creating user:', error);
  
      if (error.response && error.response.data.error.message) {
      let backendErrors = error?.response?.data?.error?.message ?? '';
  
        notification.error({
          message: user ? 'Failed to update user.' : 'Failed to create user.',
          description: backendErrors,
        });
      } else {
        notification.error({
          message: 'Operation Failed',
          description: user ? 'Failed to update user.' : 'Failed to create user.',
        });
      }
    }
  };

  return (
    <>
      <Box sx={{ width: 410 }} p={2}>
        <Typography variant="h4" color="initial">
          {user ? 'Edit User' : 'Create User'}
        </Typography>
        <IconButton
          sx={{ position: 'absolute', top: '13px', right: '8px' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ marginTop: "0" }} />

      <Box p={2} display="flex" flexDirection="column" gap={2} sx={{ maxHeight: 'calc(100vh - 150px)', overflow: 'auto','&::-webkit-scrollbar': {display: 'none'},}}>
        <Typography variant="h5">Name</Typography>
        <Controller
          name="username"
          control={control}
          rules={{
            required: "Name is required",
            minLength: {
              value: 3,
              message: "Name must be at least 3 characters long",
            },
            maxLength: {
              value: 20,
              message: "Name cannot exceed 20 characters",
            },
          }}
          render={({ field }) => (
            <>
              <TextField
                placeholder="Enter Name"
                fullWidth
                {...field}
                error={!!errors.username}
              />
              {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
            </>
          )}
        />

        <Typography variant="h5">Role</Typography>
        <Controller
          name="role"
          control={control}
          rules={{ required: "Role is required" }}
          render={({ field }) => (
            <>
              <Select
                native
                fullWidth
                {...field}
                size="small"
                error={!!errors.role}
                onChange={(e) => field.onChange(Number(e.target.value))}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {roles
                  .filter((role) => role.name !== "Public" && role.name !== "Authenticated")
                  .map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
              </Select>
              {errors.role && <ErrorText>{errors.role.message}</ErrorText>}
            </>
          )}
        />

        <Typography variant="h5">Contact No.</Typography>
        <Controller
          name="contactNumber"
          control={control}
          rules={{
            required: "Contact number is required",
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Contact number must be exactly 10 digits",
            }
          }}
          render={({ field }) => (
            <>
              <TextField
                placeholder="Enter Contact No."
                fullWidth
                inputMode="numeric"
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: 10, 
                }}
                {...field}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  field.onChange(value);
                }}
                error={!!errors.contactNumber}
              />
              {errors.contactNumber && <ErrorText>{errors.contactNumber.message}</ErrorText>}
            </>
          )}
        />

        <Typography variant="h5">Email</Typography>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Invalid email address",
            },
          }}
          render={({ field }) => (
            <>
              <TextField
                placeholder="Enter Email"
                fullWidth
                {...field}
                error={!!errors.email}
                onChange={(e) => field.onChange(e.target.value.toLowerCase())}
              />
              {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            </>
          )}
        />

        {!user && (
          <>
            <Typography variant="h5">Password</Typography>
            <Controller
              name="password"
              control={control}
              rules={{ required: "Password is required" }}
              render={({ field }) => (
                <>
                  <TextField  
                    placeholder="Enter Password"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ?  <Visibility sx={{fontSize:"1.8rem"}} /> : <VisibilityOff sx={{fontSize:"1.8rem"}} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                    error={!!errors.password}
                  />
                  {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                </>
              )}
            />
          </>
        )}

        <Typography variant="h5">Status</Typography>
        <Controller
          name="blocked"
          control={control}
          defaultValue={false}
          render={({ field }) => {
            return (
              <Switch
                color="success"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
          )}}
        />
      </Box>

      <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
        <Divider sx={{ marginTop: "0" }} />
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1.2}
          p={2}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ borderColor: "#1976D2", color: "#1976d2" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#1976D2" }}
            onClick={handleSubmit(onSubmit)}
          >
            {user ? 'Update' : 'Submit'}
          </Button>
        </Box>
      </Box>
    </>
  );
};
