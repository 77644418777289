import { Box } from "@mui/material";

export const InactiveButton = () => {
  return (
    <Box 
      sx={{
        borderRadius: 5,
        color: "#0047AB",
        background: "#ADD8E6",
        width: "70px",
        textAlign: "center",
        padding: 0.3,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        fontSize: 12,
      }}
    >
      Inactive
    </Box>
  );
};
