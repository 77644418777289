import { Box, Dialog, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ReactJson from 'react-json-view';

export const VaultDialogBox = ({ open, setOpen, jsonData }) => {
    const handleClose = () => {
      setOpen(false);
    };
  
    const parseJsonSafely = (data) => {
      if (data === null) {
        return null;
      }
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return data;
      }
    };
  
    const parsedJson = parseJsonSafely(jsonData);
  
    return (
      <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { borderRadius: '0px' } }}>
        <Box sx={{ maxWidth: '48rem', minWidth: { xs: '25rem', sm: '48rem' } }}>
          <Box sx={{ userSelect: 'none' }}>
            <Typography variant="h4" sx={{ margin: '10px 15px' }}>
              API Response
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', top: '8px', right: '8px' }}
            >
              <CloseIcon />
            </IconButton>
            <Divider />
          </Box>
          <Box sx={{ margin: '25px' }}>
            <Typography variant="h4">Response</Typography>
            <Box
              sx={{
                background: '#002453',
                color: '#ffffff',
                marginTop: '10px',
                padding: '10px',
                whiteSpace: 'pre-wrap',
                maxHeight: '300px',
                overflow: 'auto',
                lineBreak: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#6b6b6b #002453',
              }}
            >
              {parsedJson !== null ? (
                <JsonViewer data={parsedJson} />
              ) : (
                <Typography color="white">null</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    );
  };
  
  const JsonViewer = ({ data }) => {
    return (
      <ReactJson
        src={data}
        name={false}
        theme="monokai"
        style={{ backgroundColor: 'transparent' }}
        collapsed={2}
        displayDataTypes={false}
        displayObjectSize={false}
      />
    );
  };
  