import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Divider, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { JsonViewer } from './jsonViewer'; 

function ViewLogDialogBox({ open, setOpen, sequenceLogData }) {
  const handleClose = () => {
    setOpen(false);
  };

  const apiRequest = sequenceLogData?.data?.[0]?.apiRequest ?? null;
  const apiResponse = sequenceLogData?.data?.[0]?.apiResponse ?? null;

  const parseJsonSafely = (data) => {
    if (data === null) {
      return null;
    }
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return data;
    }
  };

  const requestJson = parseJsonSafely(apiRequest);
  const responseJson = parseJsonSafely(apiResponse);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { borderRadius: '0px' } }}>
          <Box sx={{ maxWidth: '48rem', minWidth: { xs: '25rem', sm: '48rem' } }}>
        <Box sx={{ userSelect: 'none' }}>
          <Typography variant="h4" sx={{ margin: '10px 15px' }}>
            API Detail
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
        </Box>
        <Box sx={{ margin: '25px' }}>
          <Typography variant="h4">Request</Typography>
          <Box
            sx={{
              background: '#002453',
              color: '#ffffff',
              marginTop: '10px',
              padding: '10px',
              whiteSpace: 'pre-wrap',
            }}
          >
            {requestJson !== null ? (
              <JsonViewer data={requestJson} />
            ) : (
              <Typography color="white">null</Typography>
            )}
          </Box>
          <Typography mt={4} variant="h4">
            Response
          </Typography>
          <Box
            sx={{
              background: '#002453',
              color: '#ffffff',
              marginTop: '10px',
              padding: '10px',
              whiteSpace: 'pre-wrap',
              maxHeight: '300px',
              overflow: 'auto',
              lineBreak: 'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: '#6b6b6b #002453',
            }}
          >
            {responseJson !== null ? (
              <JsonViewer data={responseJson} />
            ) : (
              <Typography color="white">null</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ViewLogDialogBox;
