import React, { useState } from 'react';
import { Box, Avatar, Typography, List, ListItemIcon, ListItemText, ListItemButton, Drawer, IconButton } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PasswordIcon from '@mui/icons-material/Password';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import MobileCompany from './MobileCompany';
import MobilePersonal from './MobilePersonal';
import MobileUserDetail from './MobileUserDetail';
import MobileChangePassword from './MobileChangePassword'; // Import the MobileChangePassword component
import { useSelector } from 'react-redux';

const MobileProfile = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setSelectedOption(null);
    setDrawerOpen(false);
  };

  const renderDrawerContent = () => {
    switch (selectedOption) {
      case 'companyProfile':
        return <MobileCompany onClose={handleClose}/>;
      case 'personalDetails':
        return <MobilePersonal onClose={handleClose}/>;
      case 'userDetails':
        return <MobileUserDetail onClose={handleClose}/>;
      case 'changePassword':
        return <MobileChangePassword onClose={handleClose}/>;
      default:
        return null;
    }
  };

  const getUserInitials = (username) => {
    const nameParts = username?.split(' ');
    const initials = nameParts?.map(part => part.charAt(0)).join('');
    return initials.toUpperCase();
  };

  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          bgcolor: 'background.paper', 
          paddingTop: '80px'
        }}
      >
        <Avatar 
          sx={{ 
            width: 80, 
            height: 80, 
            bgcolor: '#092147',
            marginBottom: 2,
            fontSize: 25,
          }}
        >
          {getUserInitials(user?.username)}
        </Avatar>
        <Typography variant="h4" component="div" mb={3}>
        {user?.username}
        </Typography>
      </Box>
      <Box>
        <List sx={{ width: '100%', paddingTop: '0px' }}>
          <ListItemButton
            sx={{ border: '1px solid #e0e0e0' }}
            onClick={() => handleOptionClick('companyProfile')}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Company Profile" />
            <ArrowForwardIosIcon />
          </ListItemButton>
          <ListItemButton
            sx={{ border: '1px solid #e0e0e0' }}
            onClick={() => handleOptionClick('personalDetails')}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Personal Details" />
            <ArrowForwardIosIcon />
          </ListItemButton>
          <ListItemButton
            sx={{ border: '1px solid #e0e0e0' }}
            onClick={() => handleOptionClick('userDetails')}
          >
            <ListItemIcon>
              <DeviceHubIcon />
            </ListItemIcon>
            <ListItemText primary="Users Details" />
            <ArrowForwardIosIcon />
          </ListItemButton>
          <ListItemButton
            sx={{ border: '1px solid #e0e0e0' }}
            onClick={() => handleOptionClick('changePassword')}
          >
            <ListItemIcon>
              <PasswordIcon />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
            <ArrowForwardIosIcon />
          </ListItemButton>
        </List>
      </Box>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleClose}
        transitionDuration={{ enter: 500, exit: 300 }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // height: '100%',
          }}
        >
          <IconButton onClick={handleClose}>
            {/* <CloseIcon /> */}
          </IconButton> 
          {renderDrawerContent()}
        </Box>
      </Drawer>
    </>
  );
};

export default MobileProfile;
