import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Box, Divider, Typography } from '@mui/material';
import { DeletIcon } from '../../assets';
const DeleteDialogBox = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog 
    open={open} 
    onClose={onClose} 
    maxWidth="sm"
    fullWidth
  >
      <DialogContent>
        <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Box
        sx={{mt:3 }}
        component="img"
        src={DeletIcon}
      />
        </Box>
        <DialogContentText sx={{textAlign:'center', mb:3}}>
          <Typography sx={{fontSize:'18px'}}> Are you sure you want to delete this API key ?</Typography>
        </DialogContentText>
      </DialogContent>
      <Divider/>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
       
        <Button onClick={onClose} sx={{ 
            padding: '0px 8px',  
            minWidth: '64px',   
          }} 
          variant="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained"sx={{ 
            
            padding: '2px 11px', 
            minWidth: '64px',    
          }}
           color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialogBox;
