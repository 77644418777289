import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Container, Stack, Typography, IconButton, Paper, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { PersonalDetails } from "../../assets";

const MobilePersonal = ({ onClose }) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const profileKeys = [
    { label: "Name", value: user?.username },
    { label: "Email Id", value: user?.email },
    { label: "Contact No.", value: user?.contactNumber },
  ];

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding:'0 16px',
        boxShadow: 'none',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center',}}>
        <PersonIcon sx={{ fontSize: '2rem', mr: 1 }} />
        <Typography variant="h6" component="div" sx={{ flex: 1 }}>
          Personal Details
        </Typography>
        <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{mt:0, mb:2}} />
      <Stack spacing={2} sx={{ flex: 1 }}>
        {profileKeys.map((item, index) => (
          <Stack key={index} direction="row">
            <Box sx={{width:'50%'}}> 
            <Typography
              variant="body1"
            >
              {item.label}
            </Typography>
            </Box>
            <Box sx={{width:'50%'}}>
            <Typography
              sx={{
                wordWrap: "break-word"

              }}
              variant="body1"
            >
              :&nbsp;{`${item.value}`}
            </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <Box component="img" sx={{ maxWidth: '100%' }} src={PersonalDetails} />
      </Box>
    </Paper>
  );
};

export default MobilePersonal;
