import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Typography,
  Divider,
  AppBar,
  Toolbar,
  Stack,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/reducers/auth';
import { APIkey, Billing, Contactus, Logout, Profile, finLogoFlat } from '../../assets';
import { stringAvatar } from '../../utils';
import { Wrapper } from '../wrapper';
import { totalCreditsAndRemaining } from '../../services/creditsService';

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  padding: theme.spacing(2, 6, 1, 3),
}));

export const Navbar = () => {
  const credit = useSelector((state) => state?.auth?.credits);
  const [anchorEl, setAnchorEl] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState(credit || 'NA');
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    setRemainingCredits(credit);
  }, [credit]);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const role = user?.role;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await totalCreditsAndRemaining();
        setRemainingCredits(res?.data?.credit?.remainingCredit);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const theme = useTheme();
  const navigate = useNavigate();
  const handleLogout = useCallback(() => {
    dispatch(logoutUser());
    navigate('/login');
  }, [dispatch, navigate]);

  const NAVBAR_MENU = useMemo(
    () => [
      { to: '/', label: 'Dashboard' },
      { to: '/onscreen-api', label: 'Onscreen API' },
      { to: '/api-document', label: 'API Document' },
    ],
    [],
  );

  const USER_MENU = useMemo(
    () => [
      {
        key: 'profile',
        to: '/profile',
        image: Profile,
        label: 'Profile',
      },
      {
        key: 'billing',
        to: '/billing',
        image: Billing,
        label: 'Billing',
      },
      role && role.toLowerCase() === 'admin' && {
        key: 'apikey',
        to: '/api-key',
        image: APIkey,
        label: 'API key',
      },
      {
        key: 'contactus',
        to: '/contact-us',
        image: Contactus,
        label: 'Contact us',
      },
      { key: 'divider', divider: true },
      {
        key: 'logout',
        onClick: handleLogout,
        image: Logout,
        label: 'Logout',
      },
    ].filter(Boolean),
    [handleLogout, role],
  );

  const { pathname } = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      setAnchorEl(null);
    };
    const unlisten = () => {
      return;
    };

    handleRouteChange();
    return unlisten;
  }, [pathname]);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const matches = useMediaQuery('(min-width:900px) and (max-width:1199px)');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box
        component="img"
        src={finLogoFlat}
        sx={{ maxWidth: '150px', height: 'auto', cursor: 'pointer', paddingTop:'13px', marginRight:8 }}
        onClick={() => navigate('/')}
      />
      <Divider sx={{marginTop:'2px'}}/>
      <Button
        onClick={() => navigate('/credit-balance')}
        size="small"
        variant="contained"
        sx={{ mt: 1, mr:0 }}
      >
        {`Credit Balance: ${remainingCredits}`}
      </Button>
      <Divider />
      <List>
        {NAVBAR_MENU.map(({ to, label }) => (
          <ListItem button key={label} onClick={() => navigate(to)}>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {USER_MENU.map((item) => {
          if (item.divider) {
            return <Divider key={item.key} />;
          }

          const isAdminBilling = item.key === 'billing' && user?.role?.toLowerCase() === 'admin';
          const isVisible = item.key !== 'billing' || isAdminBilling;

          if (isVisible) {
            return (
              <ListItem button key={item.key} onClick={item.onClick ? item.onClick : () => navigate(item.to)}>
                <ListItemIcon>
                  <Box component="img" src={item.image} sx={{ width: '20px', height: 'auto' }} />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          }

          return null;
        })}
      </List>
    </Box>
  );

  return (
    <AppBar
      component="nav"
      sx={{
        background: theme.palette.background.white,
        boxShadow: `0px 2px 6px ${theme.palette.shadow.black}`,
        zIndex:1200
      }}
      position="fixed"
    >
      <Wrapper>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            alignItems: 'normal',
            p: '0px !important',
            minHeight:{ xs:'55px' }
          }}
        >
          <Box
            component="img"
            src={finLogoFlat}
            sx={{ width: { xs: '100px', md: '150px' }, height: 'auto', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />

          {isMobile ? (
            <>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 1 }}
              >
                <MenuIcon sx={{ fontSize: '25px', color: '#1976d2' }} />
              </IconButton>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  zIndex: 1204,
                  display: { xs: 'block', md: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px' },
                }}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <>
              <Stack direction="row" spacing={5} mr={matches ? 0 : 38}>
                {NAVBAR_MENU.map(({ to, label }) => (
                  <Button
                    key={label}
                    sx={{ 
                      fontWeight: 500,
                      height: '100%',
                      color: pathname === to ? 'primary' : theme.palette.grey[600],
                      borderBottom: pathname === to ? `2px solid ${theme.palette.primary.main}` : 'none',
                      borderRadius: pathname === to ? '0' : 'inherit',
                    }}
                    onClick={() => navigate(to)}
                  >
                    {label}
                  </Button>
                ))}
              </Stack>
              <Stack sx={{ py: 1 }} direction="row" spacing={1} alignItems="center">
                <Button
                  onClick={() => navigate('/credit-balance')}
                  variant="contained"
                >
                  {`Credit Balance: ${remainingCredits}`}
                </Button>
                <Avatar
                  sx={{
                    bgcolor: theme?.palette?.background?.profileIcon,
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                  {...stringAvatar(user?.username ? user.username : '', theme)}
                  onClick={handleClick}
                />
              </Stack>
            </>
          )}
          <Menu
            elevation={1}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            keepMounted
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            >
            {USER_MENU.map((item) => {
              if (item.divider) {
                return <Divider key={item.key} sx={{ width: '100%' }} />;
              }

              const isAdminBilling = item.key === 'billing' && user?.role?.toLowerCase() === 'admin';

              const isVisible = item.key !== 'billing' || isAdminBilling;

              if (isVisible) {
                return (
                  <CustomMenuItem
                    key={item.key}
                    onClick={
                      item.onClick
                        ? item.onClick
                        : () => {
                          setAnchorEl(null);
                          navigate(item.to);
                        }
                    }
                  >
                    <Box component="img" src={item.image} />
                    <Typography variant="h4">{item.label}</Typography>
                  </CustomMenuItem>
                );
              }

              return null;
            })}
          </Menu>
        </Toolbar>
      </Wrapper>
    </AppBar>
  );
};
