export const DataColumns = [
    {
      title: "Name",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Status",
      dataIndex: "blocked",
    },
    {
      title: 'Action',
      dataIndex: 'action',
    }
  ];
  export const Data = [
    {
      key: "1",
      name:'Vikram Singh',
      email:'xyz@gmail.com',
      role:'User',
      status: (<span style={{ color: ' #176C02' }}>Active</span>)
    
    },
    {
      key: "2",
      name:'Vikram Singh',
      email:'xyz@gmail.com',
      role:'User',
      status: (<span style={{ color: ' #176C02' }}>Active</span>)
    },
    {
      key: "3",
      name:'Vikram Singh',
      email:'xyz@gmail.com',
      role:'User',
      status: (<span style={{ color: ' #176C02' }}>Active</span>)

    },
    {
      key: "4",
      name:'Vikram Singh',
      email:'xyz@gmail.com',
      role:'User',
      status: (<span style={{ color: ' #176C02' }}>Active</span>)

    },
    {
      key: "5",
      name:'Vikram Singh',
      email:'xyz@gmail.com',
      role:'User',
      status: (<span style={{ color: '#FF4A4A' }}>Inactive</span>)

    },
    {
      key: "6",
      name:'Vikram Singh',
      email:'xyz@gmail.com',
      role:'User',
      status: (<span style={{ color: '#FF4A4A' }}>Inactive</span>)

    },

    
  ];
  