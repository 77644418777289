import { useTheme } from '@emotion/react';
import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { remainingCredits } from '../../store/reducers/auth';
import { Logs, collectiveApiRequest } from '../../services';
import { ErrorText } from '../errorText';
import { generateValidationSchema } from './validations';
import { totalCreditsAndRemaining } from '../../services/creditsService';
import qs from 'qs';

export function InputField({
  queryParamsFlag, setloader, setJsonData, text, apiUrl, errorObject, setErrorObject, setStatus, setCurlUrl, setCurlBody, setCurlMethod, requestId // Added requestId
}) {
  const theme = useTheme();
  const [curlRequestBody, setCurlRequestBody] = useState({});
  const dispatch = useDispatch();
  const { schema, maxLengthMap } = generateValidationSchema(text);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    
    formState: { errors, isValid },
  } = form;
  
  useEffect(() => {
    reset();
  }, [text, reset]);

  const actionValueMap = {
    '/a1/search-tax-payer': 'TP',
    '/a1/view-track-returns': 'RETTRACK',
  };

  const actionValue = actionValueMap[apiUrl] || '';
  const isActionFieldDisabled = !!actionValueMap[apiUrl];

  useEffect(() => {
    if (actionValue) {
      setValue('action', actionValue, { shouldValidate: true, shouldDirty: true });
    }
  }, [apiUrl, actionValue, setValue]);
  useEffect(() => {
    if (requestId) {
      setValue('requestId', requestId, { shouldValidate: true, shouldDirty: true });
    }
  }, [requestId, setValue]);

  const handleApiRequest = async (data) => {
    try {
      setloader(true);
      setJsonData(null);
      const res = await collectiveApiRequest(data, apiUrl, queryParamsFlag);
      setStatus(res?.status);
      if (res.status === 200) {
        setJsonData(res?.data);

        setTimeout(async () => {
          const remainingCredit = await totalCreditsAndRemaining();
          if (remainingCredit?.status === 200) {
            dispatch(
              remainingCredits({
                credits: remainingCredit?.data?.credit?.remainingCredit,
              }),
            );
            setloader(false);
          }
        }, 2000);
      }
    } catch (error) {
      setloader(false);
      setErrorObject(error);
      Logs(error);
    }
  };

  useEffect(() => {
    if (errorObject) {
      setJsonData(errorObject?.response?.data || errorObject?.error);
    }
  }, [errorObject, setJsonData]);
  
  const handleInput = (e, maxLength, value) => {
    const inputValue = e.target.value.slice(0, maxLength);
    e.target.value = inputValue;
  
    let updatedCurlRequestBody = {
      ...curlRequestBody,
      [value]: inputValue || undefined,
    };
  
    const defaultFields = {};
    if (actionValue) {
      defaultFields['action'] = actionValue;
    }
    if (requestId) {
      defaultFields['requestId'] = requestId;
    }
  
    updatedCurlRequestBody = {
      ...updatedCurlRequestBody,
      ...defaultFields,
    };
  
    const filteredRequestBody = Object.fromEntries(
      Object.entries(updatedCurlRequestBody).filter(([key]) =>
        text.some((item) => item.value === key)
      )
    );
      if (inputValue) {
      filteredRequestBody[value] = inputValue;
    }
  
    const queryString = queryParamsFlag
      ? `?${qs.stringify(filteredRequestBody, { arrayFormat: 'brackets' })}`
      : '';
    const requestUrl = `${apiUrl}${queryString}`;
    const method = queryParamsFlag ? 'GET' : 'POST';
  
    setCurlRequestBody(filteredRequestBody);
    setCurlUrl(requestUrl);
    setCurlBody(queryParamsFlag ? null : filteredRequestBody);
    setCurlMethod(method);
  };
  
  
  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(handleApiRequest)}
      spacing={5}
      sx={{ mt: 2 }}
    >
      {text.length > 0 && (
        <Stack spacing={3}>
          {text.map((item) => (
            item.label && item.value && (
              <Stack key={item.id || item.value} spacing={1}>
                <Typography
                  sx={{ color: theme.palette.grey.darken4 }}
                  variant="body1"
                >
                  {item.label}
                </Typography>
                <TextField
                  sx={{ width: '75%' }}
                  onInput={(e) => handleInput(e, maxLengthMap[item.value], item.value)}
                  placeholder={`Enter ${item.label}`}
                  {...register(item.value)}
                  defaultValue={
                    item.label.toLowerCase() === 'action' 
                      ? actionValue 
                      : item.label.toLowerCase() === 'request id' 
                      ? requestId
                      : ''
                  }
                  disabled={
                    isActionFieldDisabled && item.label.toLowerCase() === 'action' 
                    || item.label.toLowerCase() === 'request id'
                  }
                />
                {item.fieldInstruction && (
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.text.secondary, marginTop: '5px' }}
                  >
                    {item.fieldInstruction}
                  </Typography>
                )}
                {!!errors[item.value] && (
                  <ErrorText>{errors[item.value].message}</ErrorText>
                )}
              </Stack>
            )
          ))}
        </Stack>
      )}

      <Button sx={{ width: 'fit-content' }} variant="contained" type="submit" disabled={!isValid}>
        Send
      </Button>
    </Stack>
  );
}
