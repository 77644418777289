import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const BasicTable = ({ columns, rows }) => {
  return (
    <TableContainer
      component={Paper}
    >
      <Table sx={{ borderCollapse: "collapse", border: "2px solid #E0E0E0", width: {xs: "1200px", md: "100%"} }}>
        <TableHead>
          <TableRow sx={{ background: "#FAFAFA" }}>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                sx={{
                  fontSize: "16px",
                  border: "2px solid #E0E0E0",
                }}
              >
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  sx={{
                    fontSize: "16px",
                    borderRight:
                      colIndex !== columns.length - 1
                        ? "2px solid #E0E0E0"
                        : "none",
                  }}
                >
                  {row[column.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
