import { createTheme } from "@mui/material/styles";
import palette from "./palette.js";
import typography from "./typography.js";

const theme = createTheme({
  typography,
  palette,
  zIndex: {
    loader: 2001,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#424242",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1.8rem",
          fontWeight: 600,
          textTransform: "capitalize",
        },
        contained: {
          fontWeight: 500,
          fontSize: "1.6rem",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E0E0E0",
          marginTop: "1rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            fontSize: "1.5rem",
            padding: "1rem",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
        },
      },
    },
  },
});
theme.shadows[1] = "0px 5px 16px -4px rgb(25 118 210 / 15%)";

export default theme;
