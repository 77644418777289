import { useTheme } from '@emotion/react';
import {
  Box, Button, Paper, Stack, Typography,IconButton,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { ResetPassword } from './changePassword';
import { Wrapper } from '../../components';
import { CompanyName } from '../../assets';
import PersonalDetail from './PersonalDetail';
import UserDetail from './UserDetail';
import MobileProfile from './MobileProfile';

import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PasswordIcon from '@mui/icons-material/Password';

export const Profile = () => {
  const theme = useTheme();
  const [current, setCurrent] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const PROFILE_KEYS = useMemo(() => {
    if (user && Object.keys(user).length !== 0) {
      return [
        {
          label: 'Company Name',
          value: user?.company?.name,
        },
        {
          label: 'Email Id',
          value: user?.company?.email,
        },
        {
          label: 'Contact No.',
          value: user?.company?.contactNumber,
        },
        {
          label: 'GSTN',
          value: user?.company?.GSTN,
        },
        {
          label: 'PAN',
          value: user?.company?.PAN,
        },
        {
          label: 'TAN',
          value: user?.company?.TAN,
        },
        {
          label: 'Address',
          value: user?.company?.address,
        },
      ];
    }
    return [];
  }, [user]);
  
  const isMobile = useMediaQuery('(max-width:899px)');

  return user ? (
    <>
    {isMobile ? <MobileProfile /> : 
    <Wrapper style={{ height: 'calc(100vh - 30px)', overflow: 'hidden' }}>
      <Paper
        sx={{
          display: 'flex',
          background: theme.palette.background.white,
          mt: 10,
          ml: 1,
        }}
      >
        <Stack sx={{ flex: 0.2, minWidth:'230px', background: theme.palette.background.main }}>
          <Button
          startIcon={<BusinessIcon />}
            sx={{
              border: '5px',
              background:
                current === 0 ? theme.palette.background.white : 'transparent',
              fontSize: '2rem',
              fontWeight: 400,
              border: current === 0 ? 'none' : '1px solid #E0E0E0',

              color:
                current === 0
                  ? theme.palette.primary.main
                  : theme.palette.textColor.primary,
              '&:hover': {
                background:
                  current === 0
                    ? theme.palette.background.white
                    : 'transparent',
              },
              '&.MuiButton-text': {
                justifyContent: 'flex-start',
              },
            }}
            onClick={() => setCurrent(0)}
          >
            Company Profile
          </Button>
          <Button
          startIcon={<PersonIcon />}
            sx={{
              background:
                current === 2 ? theme.palette.background.white : 'transparent',
              fontSize: '2rem',
              fontWeight: 400,
              border: current === 2 ? 'none' : '1px solid #E0E0E0',
              color:
                current === 2
                  ? theme.palette.primary.main
                  : theme.palette.textColor.primary,
              '&:hover': {
                background:
                  current === 2
                    ? theme.palette.background.white
                    : 'transparent',
              },
              '&.MuiButton-text': {
                justifyContent: 'flex-start',
              },
            }}
            onClick={() => setCurrent(2)}
          >
            Personal Details
          </Button>
          {user.role.toLowerCase() === 'admin' && (
            <Button
            startIcon={<DeviceHubIcon />}
              sx={{
                background:
                  current === 3 ? theme.palette.background.white : 'transparent',
                fontSize: '2rem',
                fontWeight: 400,
                border: current === 3 ? 'none' : '1px solid #E0E0E0',
                color:
                  current === 3
                    ? theme.palette.primary.main
                    : theme.palette.textColor.primary,
                '&:hover': {
                  background:
                    current === 3
                      ? theme.palette.background.white
                      : 'transparent',
                },
                '&.MuiButton-text': {
                  justifyContent: 'flex-start',
                },
              }}
              onClick={() => setCurrent(3)}
            >
              User List
            </Button>
          )}
          <Button
          startIcon={<PasswordIcon />}  
            sx={{
              background:
                current === 1 ? theme.palette.background.white : 'transparent',
              fontSize: '2rem',
              fontWeight: 400,
              border: current === 1 ? 'none' : '1px solid #E0E0E0',
              color:
                current === 1
                  ? theme.palette.primary.main
                  : theme.palette.textColor.primary,
              '&:hover': {
                background:
                  current === 1
                    ? theme.palette.background.white
                    : 'transparent',
              },
              '&.MuiButton-text': {
                justifyContent: 'flex-start',
              },
            }}
            onClick={() => setCurrent(1)}
          >
            Change Password
          </Button>
        </Stack>

        <Box
          sx={{
            flex: 0.8,
            p: 3,
            display: 'flex',
            paddingBottom: '50px',
            alignItems: [0, 1, 2].includes(current) ? 'flex-end' : 'initial',
            height: current === 3 ? 'calc(100vh - 120px)' : 'auto',
            overflow: current === 3 ? 'auto' : 'visible',
          }}
        >
          {current === 0 && (
            <>
              <Stack sx={{ flex: 0.7 }} spacing={4}>
                {PROFILE_KEYS.map((item) => (
                  <Stack key={item.label} direction="row">
                    <Typography
                      sx={{
                        flex: 1,
                        wordBreak: 'break-all',
                        color: theme.palette.grey.darken4,
                        fontSize: '1.8rem',
                      }}
                      variant="h4"
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      sx={{
                        flex: 1,
                        wordBreak: 'break-all',
                        color: theme.palette.grey.darken4,
                        fontSize: '1.8rem',
                      }}
                      variant="h4"
                    >
                      {`: ${item.value}`}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
              <Stack
                sx={{
                  flex: 0.3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  
                }}
                spacing={2}
              >
                <Box component="img" sx={{ maxWidth:'100%'}} src={CompanyName} />
              </Stack>
            </>
          )}

          {current === 1 && <ResetPassword />}

          {(current === 2) && <PersonalDetail />}
          {(current === 3) && <UserDetail />}
        </Box>
      </Paper>
    </Wrapper>
    }
    </>
  ) : null;
};

export default Profile;