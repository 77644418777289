import React, { useState, useEffect } from 'react';
import { getUsersData } from '../../services/userService';
import { DataColumns } from './constant';
import { CustomTable } from '../../components';
import { Drawer, Typography, Box, Button, Divider, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { UserDetails,PersonalDetails } from '../../assets';


function MobileUserDetail({ onClose }) {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getUsersData();
      if (Array.isArray(response.data)) {
        const sortedData = response.data
          .map(data => data.confirmed ? { ...data, confirmed: 'Active' } : { ...data, confirmed: 'Inactive' })
          .sort((a, b) => a.username.localeCompare(b.username));
        setData(sortedData);
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  const handleDrawerOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const columnsWithDrawer = DataColumns.map((column) => {
    if (column.dataIndex === 'username') {
      return {
        ...column,
        render: (text, record) => (
          <div style={{
            // textAlign: "left"
          }}>
          <Button size='small' onClick={() => handleDrawerOpen(record)}>{text}</Button>
          </div>
        ),
      };
    }
    return column;
  });

  return (
    <div style={{padding:'0 16px'}}>
      <Box sx={{ display: 'flex', alignItems: 'center',}}>
      <DeviceHubIcon sx={{ fontSize: '2rem', mr: 1 }} />
        <Typography variant="h6" component="div" sx={{ flex: 1 }}>
            User Detail        
            </Typography>
            <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
            <Divider />
          </Box>
          <Divider sx={{mt:0, mb:2}} />
      <CustomTable customClass={false} columns={columnsWithDrawer} data={Array.isArray(data) ? data : []} />
      <Drawer 
      anchor="right" 
      open={open} 
      onClose={handleDrawerClose}
      sx={{
        '& .MuiDrawer-paper': {
          width:{
            xs:'100%',
            sm:'60%',
          } 
        },
      }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column',p:2 }}
        >
          <Box position="relative">
            <Typography variant="h4" sx={{ margin: '10px 15px' }}>
              User Detail
            </Typography>
            <IconButton
              sx={{ position: 'absolute', top: '8px', right: '8px' }}
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </IconButton>
            <Divider />
          </Box>
          <Box
            sx={{
              margin: '25px',
            }}
          >
            <Box
              sx={{
                marginTop: '10px',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <Box sx={{width:'100px'}} component="img" src={UserDetails} />
              <Typography variant="h4" mt={4}>{selectedUser?.username}</Typography>
            </Box>

            <Box
              sx={{
                background: '#F7FCFF',
                marginTop: '20px'
              }}
            >
              {selectedUser && (
                <Table>
                  <TableBody > 
                    <TableRow >
                      <TableCell ><Typography variant="h6">Username:</Typography></TableCell>
                      <TableCell><Typography sx={{wordBreak:'break-word'}} variant="h6">{selectedUser.username}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Typography variant="h6">Email:</Typography></TableCell>
                      <TableCell ><Typography sx={{wordBreak:'break-word'}} variant="h6">{selectedUser.email}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Typography variant="h6">Role:</Typography></TableCell>
                      <TableCell><Typography sx={{wordBreak:'break-word'}} variant="h6">{selectedUser.role}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Typography variant="h6">Status:</Typography></TableCell>
                      <TableCell><Typography sx={{wordBreak:'break-word'}} variant="h6">{selectedUser.confirmed}</Typography></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Box component="img" sx={{ maxWidth: '100%' }} src={PersonalDetails} />
      </Box>
    </div>
  );
}

export default MobileUserDetail;
