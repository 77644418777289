import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Typography, TextField, Box, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'antd';
import { ErrorText } from '../../components';

const ApiKeyDialog = ({ open, handleClose, handleSave, apiSecret, apiKey, apiList }) => {
  const [customError, setCustomError] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    setCustomError(''); 
    const isFormValid = await trigger();
    if (isFormValid) {
      try {
        await handleSave(data.apiAccessKeysName, data.selectedApis);
      } catch (error) {
        /** Check if the error response structure matches the expected structure */
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          setCustomError(error.response.data.error.message);
        } else {
          setCustomError('An unexpected error occurred. Please try again.');
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Create API Keys</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ marginTop: 0 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm:'row', md: 'row' },
          gap: 2,
          paddingTop: 4,
        }}
      >
        <Box flex={1} pl={{ xs: 2, sm:5, md: 5 }} pr={{ xs: 2, sm:5, md: 4 }}>
          <Typography variant="h5">Name</Typography>
          <Controller
            name="apiAccessKeysName"
            control={control}
            defaultValue=""
            rules={{
              required: 'Name is required',
              minLength: { value: 3, message: 'Name must be at least 3 characters long.' },
              maxLength: { value: 50, message: 'Name must be less than 50 characters.' },
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                placeholder="Ex: Development Environment API"
                variant="outlined"
                margin="normal"
                {...field}
                error={!!errors.apiAccessKeysName}
              />
            )}
          />
          <ErrorText>{errors.apiAccessKeysName?.message}</ErrorText>
          {customError && <ErrorText>{customError}</ErrorText>}
          <Typography variant="h5" mt={3}>Select API</Typography>
          <Controller
            name="selectedApis"
            control={control}
            defaultValue={[]}
            rules={{ validate: value => value.length > 0 || 'Select at least one API' }}
            render={({ field }) => (
              <Select
                size='large'
                mode="tags"
                showSearch={false}
                dropdownStyle={{ zIndex: 1500 }}
                style={{
                  width: '100%',
                  marginTop:'16px',
                }}
                placeholder="Please Select"
                onChange={(value) => {
                  field.onChange(value);
                  setValue('selectedApis', value)
                }}
                value={field.value}
                error={!!errors.selectedApis}
              >
                {apiList.map((api) => (
                  <Select.Option key={api.key} value={api.key}>{api.apiName}</Select.Option>
                ))}
              </Select>
            )}
          />
          <ErrorText>{errors.selectedApis?.message}</ErrorText>
        </Box>
        <Box
          flex={1}
          pl={{ xs: 2, sm:3, md: 3 }}
          pr={{ xs: 2, sm:3, md: 8 }}
          pb={{ xs: 4, sm:3, md: 9 }}
          sx={{
            backgroundColor: 'rgba(245, 245, 245, 1)',
            mt: { xs: 2, sm:0, md: 0 },
          }}
        >
          <Typography variant="h5">API Key</Typography>
          <TextField
            sx={{ backgroundColor: '#002453', color: '#ffffff' }}
            fullWidth
            value={apiKey}
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
              style: { color: '#ffffff' },
            }}
          />
          <Typography variant="h5" mt={3}>API Secret Key</Typography>
          <TextField
            sx={{ backgroundColor: '#002453' }}
            fullWidth
            value={apiSecret}
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
              style: { color: '#ffffff' },
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ marginTop: 0 }} />
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined" sx={{ padding: '0px 8px', minWidth: '64px' }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained" sx={{ padding: '2px 11px', minWidth: '64px' }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiKeyDialog;
