import {
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  styled,
  Box,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { Logs, contactUs } from '../../services';
import { Check, ContactUsFrame } from '../../assets';
import { contactUsSchema } from './validations';
import { ErrorText, Wrapper } from '../../components';
import { notification } from 'antd';

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
  },
}));

export const ContactUs = () => {
  const theme = useTheme();
  const [showSuccess, setShowSuccess] = useState(false);

  const form = useForm({
    resolver: yupResolver(contactUsSchema),
    mode: 'all',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const { user } = useSelector((state) => state.auth);

  const companyId = user?.company?.id;
  const userEmail = user?.email;
  const userName = user?.username;
  const contactNumber = user?.contactNumber;

  const handleQuerySend = async (data) => {
    try {
      const res = await contactUs({
        message: data?.message,
        company: companyId,
        email: userEmail,
        name: userName,
        contactNumber,
      });
      if (res?.data) {
        setShowSuccess(true);
        notification.success({
          message: "Successfully Sent!",
          description: "Thank you for your enquiry."
        })
        form.reset();
      }
    } catch (error) {
      Logs(error);
    }
  };

  return (
    <Wrapper sx={{ height: 'calc(100vh - 30px)',  
    overflow: 
    {xs:'visible',
    md:'hidden'
  }, 
  }}>
      <form onSubmit={handleSubmit(handleQuerySend)}>
        <Box
          sx={{
            display: 'flex',
            gap: '2rem',
            margin: '10rem auto 0',
            overflow: 
            {xs:'visible',
            md:'hidden'
          },
            flexDirection:{
              xs:'column',
              md:'row'
            }
          }}
        >
          <Paper sx={{ flex: 1, overflow: 'hidden' }}>
            <Stack spacing={5} sx={{ py: 4, px: 8, overflow: 'hidden' }}>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.textColor.primary }}
              >
                We&apos;d love to hear from you!
              </Typography>

              <Stack spacing={1} sx={{ overflow: 'hidden' }}>
                <Typography variant="h5">Write us a message</Typography>
                <CustomTextField
                  multiline
                  rows={5}
                  id="message"
                  placeholder="Write us a Message"
                  {...register('message')}
                />
                {!!errors.message && (
                  <ErrorText>{errors.message.message}</ErrorText>
                )}
              </Stack>

              <Typography
                align="left"
                variant="h5"
                sx={{
                  fontWeight: 400,
                  color: theme.palette.textColor.primary,

                }}
              >
                Please give as much info as you can. Our support team will get
                back to you shortly after you submit.
              </Typography>

              <Stack alignItems="center">
                <Button
                  variant="contained"
                  size="small"
                  sx={{ marginY: 1, paddingX: 2 }}
                  type="submit"
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Paper>
          <Stack
            sx={{
              flex: 1, py: 2, px: 5, overflow: 'hidden',
            }}
            spacing={2}
          >
            <Box component="img" sx={{ width: {xs:'100%',md:'70%'} }} src={ContactUsFrame} />
            <Typography variant="h2">Let&apos;s Connect!</Typography>
            <Typography variant="h4">
              We&apos;re here to help you with all your needs. Whether you have a question, feedback to share, or a credit request, we&apos;ve got you covered.
            </Typography>
          </Stack>
        </Box>
      </form>
    </Wrapper>
  );
};
