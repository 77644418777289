import { Table } from "antd";
import classes from "./CustomTable.module.css";
import className from "./CustomTableApiKey.module.css";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

export const CustomTable = ({ columns, data, customClass=true}) => {
  columns.forEach((column) => {
    column.align = "center";
    column.style = { fontWeight: 500 };
  });

  const tableClass = customClass ? classes["custom-table"] : className["custom-table"];

  return (
    <Table
      className={tableClass}
      columns={columns}
      dataSource={data}
      onChange={onChange}
      pagination={{
        pageSize: 8,
        showSizeChanger: false,
      }}
      total={10}
      scroll={{ x: 1024 }}
    />
  );
};
