export const menu = [
  {
    title: 'Introduction',
    items: [],
  },
  {
    title: 'Authentication',
    items: [],
  },
  {
    title: 'HTTP Status Codes',
    items: [],
  },
  {
    title: 'Rate Limit',
    items: [],
  },
  {
    title: 'Fintech API Services',
    items: [{}],
  },
  {
    title: 'Glossary',
    items: [],
  },
];
