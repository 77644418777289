import { createBrowserRouter } from 'react-router-dom';
import {
  APIkey,
  ContactUs,
  Credit,
  Dashboard,
  OnscreenApi,
  Profile,
  Register,
  ResetPassword,
  VaultTable
  
} from '../pages';
import { AuthGuard } from '../utils';
import { APIDocument, Layout, ViewLogs } from '../components';
import { Billtable } from '../pages/Billingdetail.js';
import { GuestGuard } from '../utils/route-guard/GuestGuard';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: '/view-logs',
        element: <ViewLogs />,
      },
      {
        path: '/onscreen-api',
        element: <OnscreenApi />,
      },
      {
        path: '/api-document',
        element: <APIDocument />,
      },
      {
        path: '/api-key',
        element: <APIkey />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/credit-balance',
        element: <Credit />,
      },
      {
        path: '/contact-us',
        element: <ContactUs />,
      },
      {
        path: '/billing',
        element:
  <GuestGuard requiredRole="admin">
    <Billtable />
  </GuestGuard>,
      },
      {
        path: '/vault',
        element: <VaultTable />,
      },
    ],
  },
  {
    path: '/login',
    element: <Register />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },

]);
export default router;
