import {
  Typography, Grid, Box, IconButton, Paper,
} from '@mui/material';
import { useState, useMemo } from 'react';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import { CreditScreen, CreditScreenCarasouel } from '../../assets';

const styles = {
  container: {
    position: 'relative',
    height: '100%',
  },
  controls: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
  },

  imageContainer: {
    width: '100%',
    height: '100%',
    paddingTop: '40px',
    paddingRight: '10px',
    maxHeight: 'calc(100% - 20px)',
  },
  creditText: {
    color: '#007bff',
  },
};

function CustomCarousel({ res }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const extractedData = useMemo(() => {
    return res?.data?.data?.map((item) => ({
      expiryDate: item?.attributes?.expiryDate,
      currentCredit: item?.attributes?.currentCredit,
    }));
  }, [res]);

  const items = useMemo(() => {
    const sortedItems = extractedData?.map((data) => ({
      availableCredit: data?.currentCredit,
      expiryDate: data?.expiryDate,
    })).sort((a, b) => new Date(a.expiryDate) - new Date(b.expiryDate));
    setCurrentIndex(0);
    return sortedItems;
  }, [extractedData]);

  const handlePrev = () => {
    if (items?.length > 0) {
      setCurrentIndex(currentIndex === 0 ? items.length - 1 : currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (items?.length > 0) {
      setCurrentIndex(currentIndex === items.length - 1 ? 0 : currentIndex + 1);
    }
  };

  return (
    <Paper sx={{ position: 'relative', height: '100%' }}>
      <div style={styles.container}>
        {items?.length > 0 && (
          <div style={styles.controls}>
            <IconButton onClick={handlePrev} style={{ marginRight: '8px', color: currentIndex === 0 ? '#b3e5fc' : '#1976d2' }}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="body1">{`${currentIndex + 1}/${items.length}`}</Typography>
            <IconButton onClick={handleNext} style={{ marginLeft: '8px', color: currentIndex === items.length - 1 ? '#b3e5fc' : '#1976d2' }}>
              <ArrowForwardIos />
            </IconButton>
          </div>
        )}
        <div style={styles.imageContainer}>
          {items?.length > 0 ? (
            <Carousel 
              navButtonsAlwaysInvisible
              autoPlay={false}
              animation="slide"
              index={currentIndex}
              disableTouchRipple
              onChange={(index) => setCurrentIndex(index)}
              indicators={items?.length > 1 ? true : false}
            >
              {items.map((item, index) => (
                <Box
                key={index}
                sx={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  padding: '8px',
                  margin:{xs:0, sm:'0px 8px 18px 30px',md:'22px 0px 0px 15px '}, 
                  height: '125px',
                }}
              >
                <Box
                  component="img"
                  src={CreditScreen}
                  alt="item"
                  sx={{
                    marginRight: { xs: '20px', sm: '74px', md: '50px' },
                    maxHeight: { xs: '70%', sm: '80%', md: '75%' },
                    maxWidth: { xs: '70%', sm: '80%', md: '75%' },
                  }}
                />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <Typography sx={{ ...styles.creditText, typography: { xs: 'h4', sm:'h2', md: 'h2' } }} 
                    variant="h2">
                    {item?.availableCredit}
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ typography: { xs: 'h5', sm:'h4', md: 'h4' } }}>Available Credits</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography sx={{ typography: { xs: 'h5', sm:'h4', md: 'h4' } }}>
                        Expires On: {item?.expiryDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Carousel>
          ) : (
            <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              padding: '8px',
              margin:{xs:0, sm:'0px 8px 18px 30px',md:'22px 0px 0px 15px '}, 
              height: '125px',
            }}
          >
            <Box
                  component="img"
                  src={CreditScreen}
                  alt="item"
                  sx={{
                    marginRight: { xs: '20px', sm: '74px', md: '50px' },
                    maxHeight: { xs: '70%', sm: '80%', md: '75%' },
                    maxWidth: { xs: '70%', sm: '80%', md: '75%' },
                  }}
                />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h4">You&apos;ve run out of credits. Please request more!</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </div>
      <Box component="img"
        src={CreditScreenCarasouel}
        alt="CreditScreenCarasouel"
        sx={{
          position: 'absolute', bottom: '0px', right: '0px', zIndex: 2,
          height:{xs:110, sm:120, md:'auto'}

        }}
      />
    </Paper>
  );
}

export default CustomCarousel;
