import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export default function RequestParamtersTable({ tableData }) {
  const requestParamTableColumns = [
    { header: "Name", field: "name" },
    { header: "Explanation", field: "explanation" },
  ];

  const data = tableData;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ borderCollapse: "collapse", border: "2px solid #E0E0E0" }}>
          <TableHead>
            <TableRow sx={{ background: "#FAFAFA" }}>
              {requestParamTableColumns?.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontSize: "16px",
                    border: "2px solid #E0E0E0",
                  }}
                >
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {requestParamTableColumns.map((column, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      fontSize: "16px",
                      borderRight:
                        colIndex !== requestParamTableColumns.length - 1
                          ? "2px solid #E0E0E0"
                          : "none",
                    }}
                  >
                    {row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
