import apiCall from '../utils/api-call/apiCall';
import { createHeaders } from './constants';

const getApiDocsData = async () => {
  try {
    const headers = createHeaders();
    const params = {
      populate: 'deep',
    };
    return await apiCall('api-doc', 'GET', params, headers);
  } catch (error) {
    throw error;
  }
};

export { getApiDocsData };
