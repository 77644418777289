import React, { useState, useEffect } from 'react';
import { getUsersData } from '../../services/userService';
import { DataColumns } from './constant';
import { CustomTable } from '../../components';
import { Drawer, Typography, Box, Button, Divider, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserDetails } from '../../assets';
import AddIcon from '@mui/icons-material/Add';
import { CreateUser } from './CreateUser';
import EditIcon from '@mui/icons-material/Edit';

function UserDetail() {
  const [drawerType, setDrawerType] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [data, setData] = useState([]);
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getUsersData();
      if (Array.isArray(response.data)) {
        const filteredData = response.data.filter(user => user.id !== userDetails.id);
        const sortedData = filteredData
          .map(data => data.blocked ? { ...data, blocked: 'Inactive' } : { ...data, blocked: 'Active' })
          .sort((a, b) => a.username.localeCompare(b.username));
        setData(sortedData);
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleDrawerOpen = (user, type) => {
    setSelectedUser(user);
    setDrawerType(type);
  };

  const handleDrawerClose = () => {
    setDrawerType(null);
    setSelectedUser(null);
  };

  const handleCreateUser = () => {
    setSelectedUser(null);
    setDrawerType('create');
  };

  const columnsWithDrawer = DataColumns.map((column) => {
    if (column.dataIndex === 'username') {
      return {
        ...column,
        render: (text, record) => (
          <Button onClick={() => handleDrawerOpen(record, 'view')}>{text}</Button>
        ),
      };
    }
    if (column.dataIndex === 'action') {
      return {
        ...column,
        render: (text, record) => (
          <IconButton onClick={() => handleDrawerOpen(record, 'edit')}>
            <EditIcon color='primary' />
          </IconButton>
        ),
      };
    }
    return column;
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "auto" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
        <Typography variant="body1" color="#424242" fontSize={25}>Users</Typography>
        <Button variant='contained' startIcon={<AddIcon />} size='small' sx={{ backgroundColor: "#1976d2" }} onClick={handleCreateUser}>Create New</Button>
      </Box>
      <Drawer
        anchor='right'
        open={drawerType === 'create' || drawerType === 'edit'}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            width: 410,
            top: 0,
            position: 'fixed',
            right: 0,
          },
        }}
      >
        <CreateUser drawerClose={handleDrawerClose} fetchData={fetchData} user={selectedUser} />
      </Drawer>
      <Box sx={{ flexGrow: 1, overflow: 'auto', padding: "16px" }}>
        <CustomTable columns={columnsWithDrawer} data={Array.isArray(data) ? data : []} />
        <Drawer
          anchor="right"
          open={drawerType === 'view'}
          onClose={handleDrawerClose}
          PaperProps={{
            sx: {
              width: 410,
              top: 0,
              position: 'fixed',
              right: 0,
            },
          }}
        >
          <Box sx={{ width: '100%', maxHeight: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4" sx={{ margin: '16px 15px' }}>
                User Detail
              </Typography>
              <IconButton
                sx={{ position: 'absolute', top: '13px', right: '8px' }}
                onClick={handleDrawerClose}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
            </Box>
            <Box sx={{ margin: '25px' }}>
              <Box sx={{ marginTop: '10px', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Box component="img" src={UserDetails} />
                <Typography variant="h4" mt={4}>{selectedUser?.username}</Typography>
              </Box>
              <Box sx={{ background: '#F7FCFF', marginTop: '20px' }}>
                {selectedUser && (
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography variant="h6">Username:</Typography></TableCell>
                        <TableCell><Typography variant="h6">{selectedUser.username}</Typography></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><Typography variant="h6">Email:</Typography></TableCell>
                        <TableCell><Typography variant="h6">{selectedUser.email}</Typography></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><Typography variant="h6">Role:</Typography></TableCell>
                        <TableCell><Typography variant="h6">{selectedUser.role}</Typography></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><Typography variant="h6">Status:</Typography></TableCell>
                        <TableCell><Typography variant="h6">{selectedUser.blocked}</Typography></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
}

export default UserDetail;
