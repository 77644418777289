import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
const hostname = window.location.hostname;
Sentry.init({
dsn:  hostname !== "localhost:3000" ? 'https://f78db8a1a04227fe416e22d3d01cd16d@sentry.tools.hl1.in/19' : 'https://6b2cbcf137c63c947b66be2f03f101d9@sentry.tools.hl1.in/15',
environment: hostname !== "finhub-stage.habilelabs.io" ? 'production' : 'staging',
integrations: [
// See docs for support of different versions of variation of react router
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
Sentry.reactRouterV6BrowserTracingIntegration({
useEffect,
useLocation,
useNavigationType,
createRoutesFromChildren,
matchRoutes,
}),
Sentry.replayIntegration(),
],
// Set tracesSampleRate to 1.0 to capture 100%
// of transactions for tracing.
tracesSampleRate: 1.0,
// Capture Replay for 10% of all sessions,
// plus for 100% of sessions with an error
replaysSessionSampleRate: 0.1,
replaysOnErrorSampleRate: 1.0,
});