import axios from "axios";
import { baseURL } from "../../services/constants";

const apiCall = async (endpoint, method = 'GET', data = null, headers = null, responseType) => {
  try {
    const url = `${baseURL}/${endpoint}`;
    let response;

    if (method === 'GET') {
      response = await axios.get(url, {
        params: data,
        headers: headers,
        ...(responseType && {responseType: responseType})
      });
    } else if (method === 'POST') {
      response = await axios.post(url, data, { headers });
    } else if (method === 'PUT') {
      response = await axios.put(url, data, { headers });
    } else if (method === 'DELETE') {
      response = await axios.delete(url, { headers });
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export default apiCall;
