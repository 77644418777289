export const DataColumns = [
  {
    title: "S.No.",
    dataIndex: "sno",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "API Name",
    dataIndex: "apiName",
  },
  {
    title: "Document Name",
    dataIndex: "documentName",
  },
  {
    title: "Token",
    dataIndex: "token",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
  
];
