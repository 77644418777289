import apiCall from "../utils/api-call/apiCall";
import { createHeaders } from "./constants";
import { Logs } from "./logsService";

const vaultData = async () => {
  try {
    const queryParams = new URLSearchParams({
      populate: "*",
      "pagination[pageSize]": "100"
    }).toString();

    const url = `vaults?${queryParams}`;
    
    return await apiCall(url, "GET", null, createHeaders());
  } catch (error) {
    Logs(error);
  }
};

export { vaultData };
