import React from "react";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const Calender = ({ handleChange, defaultValue, value }) => {
  return (
    <RangePicker
      onChange={handleChange}
      defaultValue={defaultValue}
      value={value}
      style={{ width: '100%' }}
    />
  );
};

export { Calender };
