import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const isLogged = sessionStorage.getItem("isLogged");

  useEffect(() => {
    if (!isLogged) navigate("/login", { replace: true });
  }, [isLogged, navigate]);

  return children;
};
