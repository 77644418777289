import * as yup from "yup";
import { Validations } from "../../config";

export const loginSchema = yup.object().shape({
  email: Validations.email,
  password: Validations.password,
});
export const forgotPasswordSchema = yup.object().shape({
  email: Validations.email,
});
