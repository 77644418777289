  import React, { useState, useEffect } from "react";
  import { Box, Typography, Button, IconButton, Divider } from "@mui/material";
  import ContentCopyIcon from "@mui/icons-material/ContentCopy";
  import HeaderSimpleTable from "./HeaderSimpleTable";
  import RequestParamtersTable from "./RequestParamtersTable";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import { useNavigate } from "react-router-dom";

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard:", text);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  const APIServices = ({
    refProp,
    apiServicesData,
    selectedCategory,
    selectedSubCategory,
    showBackButton,
    openAll
  }) => {
    const [defaultApiData, setDefaultApiData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      if (selectedCategory) {
        if (apiServicesData && apiServicesData.length > 0) {
          const firstApiOfSelectedCategory = apiServicesData.find(
            (apiData) =>
              apiData.apiDetails &&
              apiData.apiDetails.length > 0 &&
              apiData.apiDetails[0].category === selectedCategory
          );
    
          if (firstApiOfSelectedCategory) {
            setDefaultApiData(firstApiOfSelectedCategory.apiDetails[0]);
          }
        }
      } else {
        if (apiServicesData?.[0]?.apiDetails?.[0]) {
          setDefaultApiData(apiServicesData[0].apiDetails[0]);
        }
      }
    }, [apiServicesData, selectedCategory]);

    const handleBackNavigation = () => {
      navigate('/onscreen-api', {
        state: {
          selectedApi: selectedSubCategory,
          selectedCategory: selectedCategory,
          showBackButton: true
        },
      });
    }
    

    const filteredApiServices = apiServicesData;

    return (
      <Box sx={{ background: "#ffffff" }} ref={refProp}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>

        <Typography
          pl={4}
          color="#1565C0"
          sx={{ fontSize: "24px", fontWeight: "500" }}
        >
          API Services
        </Typography>
        {showBackButton && (
          <Button
            sx={{ marginRight: '15px' }}
            startIcon={<ArrowBackIcon />}
            onClick={handleBackNavigation}
          >
            Go Back
          </Button>
        )}
        </Box>
        <Divider variant="middle" sx={{ marginLeft: "32px" }} />
        <Box sx={{ backgroundColor: "#ffffff", marginTop: "15px" }}>
          <Box sx={{ padding: "16px 32px", background: "#ffffff" }}>
            {filteredApiServices?.map((apiData, apiIndex) => (
              <div key={apiIndex}>
                {apiData?.apiDetails
                  ?.filter((itemData) => {
                    return (
                      itemData.category === selectedCategory &&
                      itemData.title === selectedSubCategory || openAll
                    );
                  })
                  .map((filteredItem, filteredIndex) => (
                    <div key={filteredIndex}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexWrap:'wrap'
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{ fontSize: "22px", fontWeight: "500" }}
                          >
                            {filteredItem?.title}
                          </Typography>
                          <Button
                            sx={{
                              background: "#E3F2FD",
                              borderRadius: "26px",
                              marginLeft: "10px",
                            }}
                          >
                            <Typography color="primary">{filteredItem?.method}</Typography>
                          </Button>
                        </div>
                        <div>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "500" }}
                          >
                            #{filteredItem?.category}
                          </Typography>
                        </div>
                      </Box>

                      <Box>
                        <Typography variant="h4" mt={2}>
                          Description
                        </Typography>
                        <Typography mt={2}>{filteredItem.description}</Typography>
                      </Box>

                      <Box>
                        <Typography variant="h4" mt={2}>
                          {filteredItem?.headerTitle}
                        </Typography>
                        <Typography mt={2}>
                          {filteredItem?.headerDescription}
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        margin="40px 0"
                      >
                        <Box width="80%">
                          <HeaderSimpleTable
                            tableData={filteredItem?.headerValue}
                          />
                        </Box>
                      </Box>

                      <Box>
                        <Typography variant="h4" mt={2}>
                          {filteredItem?.requestParameterTitle}
                        </Typography>
                        <Typography mt={2}>
                          {filteredItem?.requestParameterDescription}
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        margin="40px 0"
                      >
                        <Box width="80%">
                          <RequestParamtersTable
                            tableData={filteredItem?.requestParameterValue}
                          />
                        </Box>
                      </Box>

                      <Box sx={{ padding: "10px" }}>
                        <Typography variant="h4">Endpoints</Typography>
                        <Box
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            background: "#F1F5F9",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                width: "100%",
                                padding: "5px",
                                color: "#188038",
                                wordBreak: 'break-all'
                              }}
                            >
                              {filteredItem?.endpoint}
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() => handleCopy(filteredItem?.endpoint)}
                              style={{ cursor: "pointer", marginLeft: "1rem" }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={{ padding: "10px" }}>
                          <Typography variant="h4">Request Sample</Typography>
                          {filteredItem?.requestSample?.map((itemData, index) => {
                            const requestSampleString = JSON.stringify(itemData?.requestSample, null, 2);

                            return (
                              <Box
                                key={index}
                                mt={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  background: "#F1F5F9",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      width: "100%",
                                      padding: "20px 25px",
                                      color: "#188038",
                                    }}
                                  >
                                    <pre>
                                      {requestSampleString}
                                    </pre>
                                  </Typography>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={() => handleCopy(requestSampleString)}
                                    style={{ cursor: "pointer", marginLeft: "1rem" }}
                                  >
                                    <ContentCopyIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>

                      <Box sx={{ padding: "10px" }}>
                        <Typography variant="h4">Response Sample</Typography>
                      </Box>
                      {filteredItem?.responseSample?.map((itemData, index) => {
                        return (
                          <div key={index}>
                            <Box sx={{ padding: "10px" }}>
                              <Box display="flex" justifyContent="space-between">
                                <Typography variant="h4">
                                  {itemData?.description}
                                </Typography>
                                <Typography
                                  sx={{
                                    background: "#E3F2FD",
                                    padding: "3px 25px",
                                    borderRadius: "20px",
                                    color: "#1976D2",
                                  }}
                                >
                                  Status code - {itemData?.code}
                                </Typography>
                              </Box>
                              <Box
                                mt={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  background: "#F1F5F9",
                                  overflowY: "scroll",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      width: "100%",
                                      padding: "20px 25px",
                                      color: "#188038",
                                    }}
                                  >
                                    <pre>
                                      {JSON.stringify(itemData?.json, null, 1)}
                                    </pre>
                                  </Typography>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={() =>
                                      handleCopy(
                                        JSON.stringify(itemData?.json, null, 1)
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <ContentCopyIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        );
                      })}

                    </div>
                  ))}
              </div>
            ))}
            {defaultApiData && !selectedSubCategory && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                      {defaultApiData?.title}
                    </Typography>
                    <Button
                      sx={{
                        background: "#E3F2FD",
                        borderRadius: "26px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography color="primary">{defaultApiData?.method}</Typography>
                    </Button>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      #{defaultApiData?.category}
                    </Typography>
                  </div>
                </Box>

                <Box>
                  <Typography variant="h4" mt={2}>
                    Description
                  </Typography>
                  <Typography mt={2}>{defaultApiData.description}</Typography>
                </Box>

                <Box>
                  <Typography variant="h4" mt={2}>
                    {defaultApiData?.headerTitle}
                  </Typography>
                  <Typography mt={2}>
                    {defaultApiData?.headerDescription}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  margin="40px 0"
                >
                  <Box width="80%">
                    <HeaderSimpleTable tableData={defaultApiData?.headerValue} />
                  </Box>
                </Box>

                <Box>
                  <Typography variant="h4" mt={2}>
                    {defaultApiData?.requestParameterTitle}
                  </Typography>
                  <Typography mt={2}>
                    {defaultApiData?.requestParameterDescription}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  margin="40px 0"
                >
                  <Box width="80%">
                    <RequestParamtersTable
                      tableData={defaultApiData?.requestParameterValue}
                    />
                  </Box>
                </Box>

                <Box sx={{ padding: "10px" }}>
                  <Typography variant="h4">Endpoints</Typography>
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      background: "#F1F5F9",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          width: "100%",
                          padding: "5px",
                          color: "#188038",
                        }}
                      >
                        {defaultApiData?.endpoint}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => handleCopy(defaultApiData?.endpoint)}
                        style={{ cursor: "pointer", marginLeft: "1rem" }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

               <Box sx={{ padding: "10px" }}>
                  <Typography variant="h4">Request Sample</Typography>
                  {defaultApiData?.requestSample?.map((itemData, index) => {
                    const requestSampleString = JSON.stringify(itemData?.requestSample, null, 2);

                    return (
                      <Box
                        key={index}
                        mt={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          background: "#F1F5F9",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              width: "100%",
                              padding: "20px 25px",
                              color: "#188038",
                            }}
                          >
                            <pre>
                              {requestSampleString}
                            </pre>
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            onClick={() => handleCopy(requestSampleString)}
                            style={{ cursor: "pointer", marginLeft: "1rem" }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>  

                <Box sx={{ padding: "10px" }}>
                  <Typography variant="h4">Response Sample</Typography>
                </Box>
                {defaultApiData?.responseSample?.map((itemData, index) => {
                  return (
                    <div key={index}>
                      <Box sx={{ padding: "10px" }}>
                        <Box display="flex" 
                        justifyContent="space-between" sx={{flexWrap:'wrap',gap:'10px'}}>
                          <Typography variant="h4">
                            {itemData?.description}
                          </Typography>
                          <Typography
                            sx={{
                              background: "#E3F2FD",
                              padding: "3px 25px",
                              borderRadius: "20px",
                              color: "#1976D2",
                            }}
                          >
                            Status code - {itemData?.code}
                          </Typography>
                        </Box>
                        <Box
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            background: "#F1F5F9",
                            overflowY: "scroll",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                width: "100%",
                                padding: "20px 25px",
                                color: "#188038",
                              }}
                            >
                              <pre>{JSON.stringify(itemData?.json, null, 1)}</pre>
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() =>
                                handleCopy(
                                  JSON.stringify(itemData?.json, null, 1)
                                )
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  );
                })}

              </div>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  export default APIServices;
