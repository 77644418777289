/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Check, finLogo, ResetPasswordIcon, FooterLogo } from '../../assets';
import { resetSchema } from './validations';
import {
  resetPasswordApi,
  resetPasswordCodeCheck,
} from '../../services/authService';
import { CustomBox, LoginContainer } from '../login/Register';
import { ErrorText, Loader } from '../../components';
import { Logs } from '../../services';
import { notification } from 'antd';

export function ResetPassword() {
  const { search } = useLocation();
  const code = search.split('=')[1];
  const [showResetUI, setShowResetUI] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [passwordNotMatched, setPasswordNotMatched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();
  const form = useForm({
    resolver: yupResolver(resetSchema),
    mode: 'all',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    const checkCodeValidity = async () => {
      if (code) {
        try {
          const res = await resetPasswordCodeCheck(code);
          setLoading(false); // Set loading to false when receiving response
          if (res?.data?.message === 'Reset code is valid') {
            setShowResetUI(true);
          } else {
            setShowResetUI(false);
          }
        } catch (error) {
          setLoading(false); // Set loading to false on error
          Logs(error);
        }
      }
    };

    checkCodeValidity();
  }, [code]);

  const handleResetPassword = async (data) => {
    try {
      const res = await resetPasswordApi({
        newPassword: data['new-password'],
        confirmPassword: data['confirm-password'],
        code,
      });

      if (res?.status === 200) {
        notification.success({
          message: "Password Updated!",
          description: "Password has been changed Successfully use your new password to login."
        })
        navigate('/login');
        setShowSuccess(true);
      } 
    } catch (error) {
      const { message } = error;
      setPasswordNotMatched(message);
      Logs(error);
    }
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword,
    );
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword !== e.target.value) {
      setPasswordNotMatched(true);
    } else {
      setPasswordNotMatched(false);
    }
  };

  return (
    <>
      <CustomBox>
        <LoginContainer>
          <Box
            component="form"
            sx={{
              background: '#ffffff',
              width: '100%',
              maxWidth: '30rem',
              minWidth: { xs: '90%', sm: '48rem' },
              padding: { xs: '24px 16px', sm: '24px 64px' },
              overflow: 'auto',
              margin: "10px 0"
            }}
            onSubmit={handleSubmit(handleResetPassword)}
          >
            <Stack spacing={2}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: { xs: 0, md: 1 },
                }}
              >
                <Box
                  component="img"
                  src={finLogo}
                  sx={{
                    width: '120px',
                    height: 'auto',
                  }}
                />
              </Box>
              <Typography variant="h3" sx={{ textAlign: 'center' }}>
                Reset Password
              </Typography>
              {loading ? (
                <Loader />
              ) : !showResetUI ? (
                <Box
                  sx={{
                    backgroundColor: 'lightblue',
                    textAlign: 'center',
                    py: 12,
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                      left: '50%',
                    }}
                  >
                    <Box
                      component="img"
                      src={ResetPasswordIcon}
                      sx={{ width: '60px', height: '60px' }}
                    />
                    <Box sx={{ mt: 2, width: {xs: "180px",sm: '350px'} }}>
                      <Typography variant="h5">
                        The password reset link has expired.
                        <br />
                        Please request a new one.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <>
                  {passwordNotMatched && (
                    <ErrorText>Passwords do not match</ErrorText>
                  )}
                  <Box>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      New Password
                    </Typography>
                    <TextField
                      fullWidth
                      id="new-password"
                      type={showNewPassword ? 'text' : 'password'}
                      {...register('new-password')}
                      disabled={!showResetUI}
                      value={newPassword}
                      onChange={(e)=>setNewPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleNewPasswordVisibility}>
                              {showNewPassword ? (
                                <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  sx={{ fontSize: 20 }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!!errors['new-password'] && (
                      <ErrorText>{errors['new-password'].message}</ErrorText>
                    )}
                  </Box>
                  <Box>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      Confirm Password
                    </Typography>
                    <TextField
                      fullWidth
                      id="confirm-password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      {...register('confirm-password')}
                      disabled={!showResetUI}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  sx={{ fontSize: 20 }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!!errors['confirm-password'] && (
                      <ErrorText>
                        {errors['confirm-password'].message}
                      </ErrorText>
                    )}
                  </Box>
                  <Box sx={{ pt: 2 }}>
                    <Button
                      sx={{ fontSize: '1.8rem', fontWeight: 600 }}
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={!showResetUI}
                    >
                      Submit
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                      sx={{
                        fontSize: "16px",
                        fontWeight: "medium",
                        '&:hover': {
                          textDecoration: 'underline',
                          backgroundColor: 'transparent',
                        },
                      }}
                        onClick={() => {
                          navigate('/login');
                        }}
                      >
                        Back to login
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </Stack>
            {!showResetUI && !loading && (
            <Box
              sx={{
                background: '#ffffff',
                width: '100%',
                padding: '15px 0px 5px 0px',
              }}
            >
              <Button
                sx={{ mb: 7 }}
                variant="outlined"
                onClick={() => {
                  navigate('/login', { state: { showForgotPassword: true } });
                }}
                fullWidth
              >
                Resend Link
              </Button>
            </Box>
          )}
            <Box
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
        <Box component="img" src={FooterLogo} />
          <Typography ml={0.5} sx={{ fontSize: '16px', color: '#ffffff' }}>
            <Link
              to="https://www.habilelabs.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#757575' }}
              onMouseEnter={(e) => {
                e.target.style.color = "#1976d2";
                e.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "#757575";
                e.target.style.textDecoration = "none";
              }}
            >
              Powered by Habilelabs
            </Link>
          </Typography>
        </Box>
          </Box>
        </LoginContainer>
      </CustomBox>
    </>
  );
}
