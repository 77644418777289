const palette = {
  background: {
    main: "#F5F5F5",
    white: "#FFF",
    default: "#E0E0E0",
    profileIcon: "#092147",
    login: "#082430",
    light: "#E3F2FD",
    overlay: `rgba(0,0,0,0.5)`,
  },
  chart: {
    fetched: "#1976D2",
    failed: "#FF6868",
  },
  grey: {
    lighten: "#BDBDBD",
    darken: "#616161",
    darken4: "#212121",
  },
  errorText: "#FF0000",
  arrows: {
    dark: "#E3F2FD",
    light: "#ECECEC",
  },
  textColor: {
    primary: "#000000",
    grey: "#757575",
    blue: "#1976D2",
    warning: "#FF0000",
    white: "#FFF",
  },
  divider: "#BDBDBD",
  shadow: {
    black: "rgba(0, 0, 0, 0.5)",
    blue: "#1976D2",
  },
};
export default palette;
