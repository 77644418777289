import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { loginSchema } from "./validations";
import { loginApi } from "../../services/authService";
import { loginUser } from "../../store/reducers/auth";
import { finLogo, FooterLogo } from "../../assets";
import { ErrorText } from "../../components";
import { Logs } from "../../services";

export function Login({ handleForgotPassword }) {
  const form = useForm({
    resolver: yupResolver(loginSchema),
    mode: "all",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = form;
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (data) => {
    setIsLoading(true);
    try {
      const email = data?.email.toLowerCase();
      const res = await loginApi(email, data?.password);
      if (res.status === 200) {
        dispatch(loginUser(res.data));
        navigate("/", { replace: true });
      }
    } catch (error) {
      setInvalidCredentials(true);
      Logs(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const email = watch("email");
  const password = watch("password");

  useEffect(() => {
    if (email === "" || password === "") {
      setInvalidCredentials(false);
    }
  }, [email, password]);

  return (
    <Box>
      <Box
        component="form"
        sx={{
          background: "#ffffff",
          width: "100%",
          maxWidth: "30rem",
          minWidth: { xs: "90%", sm: "48rem" },
          padding: { xs: "24px 16px", sm: "24px 64px" },
          overflow: "auto",
          margin: "10px 0"
        }}
        onSubmit={handleSubmit(handleLogin)}
      >
        <Stack spacing={1} sx={{ maxHeight: "50rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: { xs: 0, md: 1 },
            }}
          >
            <Box
              component="img"
              src={finLogo}
              sx={{ width: "120px", height: "auto" }}
            />
          </Box>
          <Stack spacing={3}>
            {invalidCredentials ? (
              <ErrorText>
                The email or password you entered is incorrect.
              </ErrorText>
            ) : null}
            <Box>
              <Typography variant="h5" sx={{ mb: 1 }}>
                Email
              </Typography>
              <TextField
                fullWidth
                id="email"
                type="email"
                placeholder="Email"
                {...register("email")}
              />
              {!!errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            </Box>
            <Box>
              <Typography variant="h5" sx={{ mb: 1 }}>
                Password
              </Typography>
              <TextField
                fullWidth
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                {...register("password")}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: 22 }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: 22 }} />
                      )}
                    </IconButton>
                  ),
                }}
              />
              {!!errors.password && (
                <ErrorText>{errors.password.message}</ErrorText>
              )}
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Button
                sx={{
                  fontSize: "16px",
                  fontWeight: "medium",
                  marginRight: "-9px",
                  marginTop: "-22px",
                  "&:hover": {
                    textDecoration: "underline",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => handleForgotPassword()}
              >
                Forgot password?
              </Button>
            </Box>
            <Button
              sx={{ fontSize: "1.8rem", fontWeight: 600 }}
              type="submit"
              variant="contained"
              fullWidth
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Log in"}
            </Button>
          </Stack>
        </Stack>
        <Typography mt={2} sx={{ textAlign: "center", fontSize: "16px" }}>
          Don&apos;t Have Account?{" "}
          <Link
            to="https://www.habilelabs.io/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#1976d2",
              textDecoration: "none",
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "#1976d2";
              e.target.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "#1976d2";
              e.target.style.textDecoration = "none"; 
            }}
          >
            Contact us
          </Link>
        </Typography>

        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "40px" }}
        >
          <Box component="img" src={FooterLogo} />
          <Typography ml={0.5} sx={{ fontSize: "16px", color: "#ffffff" }}>
            <Link
              to="https://www.habilelabs.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#757575" }}
              onMouseEnter={(e) => {
                e.target.style.color = "#1976d2";
                e.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "#757575";
                e.target.style.textDecoration = "none";
              }}
            >
              Powered by Habilelabs
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
