import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "@mui/material";



const formatDateString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const getMonthRange = () => {
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
  return {
    startDate: formatDateString(defaultStartDate),
    endDate: formatDateString(defaultEndDate),
  };
};
export { getMonthRange };

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}




const CurlRequestGenerator = ({
  method = "GET",
  url,
  headers = {},
  body = null,
  compressed = true,
  apiUrl,
  queryParamsFlag
}) => {
  const [copied, setCopied] = useState(false);
  const [curlBody, setCurlBody] = useState(body);
  const prevApiUrlRef = useRef(apiUrl);

  useEffect(() => {
    if (prevApiUrlRef.current !== apiUrl) {
      setCurlBody(null);
      prevApiUrlRef.current = apiUrl;
    }
  }, [apiUrl]);

  useEffect(() => {
    if (body) {
      setCurlBody(body);
    }
  }, [body]);

  const generateCurlCommand = () => {
    let curlCommand = `curl -X ${method} '${url}'`;

    Object.keys(headers).forEach((key) => {
      curlCommand += ` -H '${key}: ${headers[key]}'`;
    });

    if ((method === "POST" || method === "PUT") && curlBody) {
      if (curlBody instanceof FormData) {
        for (const [key, value] of curlBody.entries()) {
          if (value instanceof File) {
            curlCommand += ` -F '${key}=@${value.name}'`;
          } else {
            curlCommand += ` -F '${key}=${value}'`;
          }
        }
      } else {
        curlCommand += ` -H 'Content-Type: application/json' -d '${JSON.stringify(curlBody)}'`;
      }
    }

    if (compressed) {
      curlCommand += " --compressed";
    }

    return curlCommand;
  };

  const handleCopyClick = () => {
    const curlCommand = generateCurlCommand();
    navigator.clipboard.writeText(curlCommand).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={!curlBody && !queryParamsFlag}
      startIcon={<ContentCopyIcon />}
      onClick={handleCopyClick}
      size="small"
      sx={{
        height: '40px',
        fontSize: '1.2rem', 
        padding: '2px 8px',
      }}
    >
      {copied ? "Copied!" : "Copy cURL"}
    </Button>
  );
};



export default CurlRequestGenerator;



