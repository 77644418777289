import axios from "axios";
import { baseURL, createHeaders } from "./constants";

const creditsPerApi = async (params) => {
  try {
    const { id } = params;
    return axios.get(`${baseURL}/companies?populate=*&filters[id]=${id}`, {
      headers: createHeaders()
    });
  } catch (error) {
    throw error;
  }
};

export { creditsPerApi };

const totalCreditsAndRemaining = async (token) => {
  try {
    return axios.get(`${baseURL}/total-active-credit`, {
      headers: createHeaders()
    });
  } catch (error) {
    throw error;
  }
};

export { totalCreditsAndRemaining };


const activePlansSummary = async (params) => {
  const { id } = params;
  try {
    return axios.get(`${baseURL}/assign-credits?populate=*&filters[status]=ACTIVE&filters[company][id]=${id}`, {
      headers: createHeaders() });
  } catch (error) {
    throw error;
  }
};

export { activePlansSummary };
