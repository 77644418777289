import axios from 'axios';
import { onscreenTestHeaders, baseURL, createHeaders } from './constants';
import { InputNumber } from 'antd';

const hostname = window.location.hostname;

const onscreenApiUrl =  
(hostname === "localhost" || hostname === "finhub-stage.habilelabs.io")
 ? 'https://api-gate-stage.finhub.habilelabs.io' : 'https://api-gate.finhub.habilelabs.io';
  

 const collectiveApiRequest = async (payload, apiUrl, queryParamsFlag = false) => {
  if (queryParamsFlag) {
    const queryParams = new URLSearchParams(payload).toString();
    return axios.get(`${onscreenApiUrl}${apiUrl}?${queryParams}`, {
      headers: onscreenTestHeaders(),
    });
  } else {
    return axios.post(`${onscreenApiUrl}${apiUrl}`, payload, {
      headers: onscreenTestHeaders(),
    });
  }
};


const aadhaarMasking = async (payload) => {
  const { apiKey, apiSecret, uploadImage } = payload;
  const headers = {
    api_key: apiKey,
    api_secret: apiSecret,
    api_url: 'aadhaar-masking',
    maxBodyLength: Infinity,
  };
  return axios.post(`${onscreenApiUrl}/v1/aadhaar-masking`, uploadImage, {
    headers,
  });
};

const createDigiLockerUrl = async () => {
  return axios.post(`${onscreenApiUrl}/s1/digilocker-iframe-createurl`, {
    "signup": true,
  });
};

const panVerification = async (payload) => {
  const { apiKey, apiSecret, entity } = payload;
  const headers = {
    api_key: apiKey,
    api_secret: apiSecret,
  };

  return axios.post(
    `${onscreenApiUrl}/pan-verification`,
    { entity },
    { headers },
  );
};

const updatedCredits = async (id) => {
  return axios.get(`${baseURL}/companies/${id}`);
};


const postFile = async (payload) => {
  const { file, number } = payload;
  const formData = new FormData();

  formData.append('file', file);
  if (number){
    formData.append('number', number);
  }

  return axios.post(
    `https://api-gate.finhub.habilelabs.io/api/v1/vault`, 
    formData, 
    {
      headers: {
        ...onscreenTestHeaders(),
        'Content-Type': 'multipart/form-data'
      }
    }
  )
};

const downloadToken = async (token) => {
  const url = `https://api-gate.finhub.habilelabs.io/api/v1/un-vault?token=${token}`;
  try {
    const response = await axios.get(url, { headers: onscreenTestHeaders(), responseType: 'blob' });
    return response;
  } catch (error) {
    console.error('Error downloading data:', error);
    throw error;
  }
};


const postFileToVaults = async (payload) => {
  const { token, documentName, documentType, response, apiName  } = payload;

  return axios.post(`${baseURL}/vaults`, {
    data: {
      token: token,
      documentName: documentName,
      documentType: documentType,
      response: response || '',
      apiName: apiName
    } 
  }, {headers: createHeaders()});
};

export {
  aadhaarMasking,
  updatedCredits,
  panVerification,
  collectiveApiRequest,
  postFile,
  postFileToVaults,
  downloadToken,
  createDigiLockerUrl
};
