import { updatedCredits } from "../services";

const stringAvatar = (name = "", theme = {}) => {
  const [firstname, lastname] = name.split(" ");
  let iconName = "";
  firstname ? (iconName += `${firstname[0].toUpperCase()}`) : (iconName += "");
  lastname ? (iconName += `${lastname[0].toUpperCase()}`) : (iconName += "");
  return {
    children: iconName.length > 0 ? iconName : "NA",
  };
};
const SentenceCase = (s) => {
  return s
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(" ");
};
const getFormattedDate = (dateString) => {
  const inputDate = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = inputDate.toLocaleDateString("en-US", options);
  return formattedDate;
};

const getUpdatedCredits = async () => {
  try {
    const { id } = JSON.parse(sessionStorage.getItem("userDetails"));
    const res = await updatedCredits(id);
    return res;
  } catch (error) {
    throw error;
  }
};

const formatDate = (date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export {
  SentenceCase,
  getFormattedDate,
  stringAvatar,
  getUpdatedCredits,
  formatDate,
};
