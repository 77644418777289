import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
export const ErrorText = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h4"
      sx={{
        fontSize: "1.6rem",
        lineHeight: "2.4rem",
        color: theme.palette.errorText,
        mt: 1,
      }}
    >
      {children}
    </Typography>
  );
};
