import { Box, Typography } from "@mui/material";
import { LoginBg } from "../../assets";
import { useEffect, useState } from "react";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./login";
import styled from "@emotion/styled";
import { useLocation } from 'react-router-dom';

export const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "100svh",
  backgroundSize: "cover !important",
  backgroundRepeat: "no-repeat",
  backgroundColor:"#002045 !important",
  background: `url(${LoginBg})`,
  [theme.breakpoints.down("sm")]: {
    background: "none",
  },
}));

export const LoginContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: theme.spacing(1, 2.5),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 2.5),
  },
}));

export const Register = () => {
  const location = useLocation();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleBackLogin = () => {
    setShowForgotPassword(false);
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  useEffect(() => {
    if (location?.state && location.state.showForgotPassword) {
      setShowForgotPassword(true);
    }
  }, [location.state]);

  return (
    <CustomBox>
      <LoginContainer>
        {showForgotPassword ? (
          <ForgotPassword handleBackLogin={handleBackLogin} />
        ) : (
          <Login handleForgotPassword={handleForgotPassword} />
        )}
      </LoginContainer>
    </CustomBox>
  );
};
