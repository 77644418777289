import { Button } from "@mui/material";

export const TableButton = (props) => {
  return (
    <Button
      sx={{
        "&:hover": {
          textDecoration: "underline",
        },
        fontSize: 14,
        fontWeight: 500,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
