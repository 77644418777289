import { Button } from "@mui/material";
import { TableButton } from "../../components";
export const DataColumns = [
  {
    title: "S.No.",
    dataIndex: "serialNumber",
  },
  {
    title: "Date of Billing",
    dataIndex: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Amount",
    dataIndex: "price",
  },
  {
    title: "Credits",
    dataIndex: "credits",
  },
  
  {
    title: "Expired On",
    dataIndex: "expired",
  },
];

export const Data = [
  {
    key: "1",
    orderNumber: "109",
    dataBilling: "22/1/2024",
    status: <Button sx={{}}>Active</Button>,

    price: 240,
    cridts: 130,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
  {
    key: "2 ",
    orderNumber: "108",
    dataBilling: "22/1/2024",
    status: (
      <Button
        sx={{
          borderRadius: 5,
          color: "#008234",
          background: "#BBFFA3",
          width: "58px",
          padding: 0.3,
          fontSize: 12,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        Active
      </Button>
    ),
    price: 200,
    cridts: 120,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
  {
    key: "3",
    orderNumber: "107",
    dataBilling: "22/1/2024",
    status: <Button sx={{}}>Expired </Button>,
    price: 200,
    cridts: 140,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
  {
    key: "4",
    orderNumber: "106",
    dataBilling: "22/1/2024",
    status: (
      <Button
        sx={{
          borderRadius: 5,
          color: "#FF6868",
          background: "#FFD1D1",
          width: "70px",
          textAlign: "center",
          padding: 0.3,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          fontSize: 12,
        }}
      >
        Expired{" "}
      </Button>
    ),
    price: 200,
    cridts: 150,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
  {
    key: "5",
    orderNumber: "105",
    dataBilling: "22/1/2024",
    status: (
      <Button
        sx={{
          borderRadius: 5,
          color: "#FF6868",
          background: "#FFD1D1",
          width: "70px",
          textAlign: "center",
          padding: 0.3,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          fontSize: 12,
        }}
      >
        Expired{" "}
      </Button>
    ),
    price: 150,
    cridts: 130,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
  {
    key: "6",
    orderNumber: "104",
    dataBilling: "22/1/2024",
    status: (
      <Button
        sx={{
          borderRadius: 5,
          color: "#FF6868",
          background: "#FFD1D1",
          width: "70px",
          textAlign: "center",
          padding: 0.3,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          fontSize: 12,
        }}
      >
        Expired{" "}
      </Button>
    ),
    price: 180,
    cridts: 140,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
  {
    key: "7",
    orderNumber: "103",
    dataBilling: "22/1/2024",
    status: (
      <Button
        sx={{
          borderRadius: 5,
          color: "#FF6868",
          background: "#FFD1D1",
          width: "70px",
          textAlign: "center",
          padding: 0.3,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          fontSize: 12,
        }}
      >
        Expired{" "}
      </Button>
    ),
    price: 190,
    cridts: 130,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
  {
    key: "8",
    orderNumber: "102",
    dataBilling: "22/1/2024",
    status: (
      <Button
        sx={{
          borderRadius: 5,
          color: "#FF6868",
          background: "#FFD1D1",
          width: "70px",
          textAlign: "center",
          padding: 0.3,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          fontSize: 12,
        }}
      >
        Expired{" "}
      </Button>
    ),
    price: 240,
    cridts: 160,
    expired: "22/02/2024",
    invoice: <TableButton>Download</TableButton>,
  },
];
