import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
// import Button from '@mui/material/Button'; // Import Button from Material-UI

const DateTimeGraph = ({ data = {} }) => {
  const chartRef = useRef(null);
  const seriesData = Object.entries(data).map(([date, value]) => {
    const timestamp = new Date(date).getTime();
    return [timestamp, value];
  });
  
  // useEffect(() => {
  //   const chart = chartRef.current.chart;

  //   const resetCssClasses = (activeEl) => {
  //     const els = document.querySelectorAll('button');
  //     Array.prototype.forEach.call(els, (el) => {
  //       el.classList.remove('active');
  //     });
  //     activeEl.target.classList.add('active');
  //   };

  //   document.querySelector('#one_month').addEventListener('click', (e) => {
  //     resetCssClasses(e);
  //     const today = new Date();
  //     const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
  //     chart.zoomX(oneMonthAgo.getTime(), today.getTime());
  //   });

  //   document.querySelector('#six_months').addEventListener('click', (e) => {
  //     resetCssClasses(e);
  //     const today = new Date();
  //     const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
  //     chart.zoomX(sixMonthsAgo.getTime(), today.getTime());
  //   });

  //   document.querySelector('#one_year').addEventListener('click', (e) => {
  //     resetCssClasses(e);
  //     const today = new Date();
  //     const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
  //     chart.zoomX(oneYearAgo.getTime(), today.getTime());
  //   });

  //   document.querySelector('#seven_days').addEventListener('click', (e) => {
  //     resetCssClasses(e);
  //     const today = new Date();
  //     const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  //     chart.zoomX(sevenDaysAgo.getTime(), today.getTime());
  //   });

  //   document.querySelector('#all').addEventListener('click', (e) => {
  //     resetCssClasses(e);
  //     const today = new Date();
  //     const twoYearsAgo = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());
  //     chart.zoomX(twoYearsAgo.getTime(), today.getTime());
  //   });
  // }, []);

  const options = {
    series: [{
      data: seriesData
    }],
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      },
      y: {
        title: {
          formatter: function (val) {
            return 'No. of consumption :'
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  };
  
  return (
    <div>
      {/* <div style={{gap: "10px", display: "flex", justifyContent: "end", padding: "15px 20px 0px 0px" }} id="buttons">
        <Button id="one_month" variant="outlined" size="small" style={{ fontSize: "15px", padding: "5px" }}>1M</Button>
        <Button id="six_months" variant="outlined" size="small" style={{ fontSize: "15px", padding: "5px" }}>6M</Button>
        <Button id="one_year" variant="outlined" size="small" style={{ fontSize: "15px", padding: "5px" }}>1Y</Button>
        <Button id="seven_days" variant="outlined" size="small" style={{ fontSize: "15px", padding: "5px" }}>7D</Button>
        <Button id="all" variant="outlined" size="small" style={{ fontSize: "15px", padding: "3px" }}>All</Button>
      </div> */}
      <Chart options={options} series={options.series} type="area" height={350} ref={chartRef} />
    </div>
  );
};

export default DateTimeGraph;
