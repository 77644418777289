import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogActions, Button, Typography, TextField, Box, Divider, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { creditsPerApi } from '../../services/creditsService';
import { apiCategortList, generateApiKeyAndToken } from '../../services';
import { getKeyPairById, editKeyPair } from '../../services/keysPairService';
import { saveApiKeyAndToken } from '../../services/saveKeysService'; 
import { ErrorText } from '../../components';

const { Option } = Select;

const EditDialogBox = ({ open, handleClose, handleSave, editData }) => {
  const [apiList, setApiListData] = useState([]);
  const [customError, setCustomError] = useState('');
  const { user } = useSelector((state) => state.auth);

  const { control, handleSubmit, setValue, formState: { errors }, register, trigger } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      apiKey: '',
      apiSecret: '',
      selectedApis: [],
    }
  });

  useEffect(() => {
    fetchCreditsPerApi();
    if (editData) {
      fetchApiDataById(editData.id);
    } else {
      handleRegenerate();
    }
  }, [editData]);

  const handleRegenerate = async () => {
    try {
      const res = await generateApiKeyAndToken();
      if (res?.data) {
        const { apiKey, apiSecret } = res.data.data;
        setValue('apiKey', apiKey);
        setValue('apiSecret', apiSecret);
      } else {
        console.error('Response data is empty');
      }
    } catch (error) {
      console.error('Error regenerating API key and secret:', error);
    }
  };

  const fetchCreditsPerApi = async () => {
    try {
      const response = await apiCategortList();
      const apisByCategory = response.data?.data?.attributes?.apisByCategory;
      if (apisByCategory) {        
        const apiList = [];
        
        Object.values(apisByCategory).forEach(category => {
            category.apis.forEach(api => {
                apiList.push({
                    value: api.id,
                    label: api.name,
                });
            });
        });
        
        setApiListData(apiList);
    }
    } catch (error) {
      console.error('Error fetching credits per API:', error);
    }
  };

  const fetchApiDataById = async (id) => {
    try {
      const response = await getKeyPairById(id);
      const { apiKey, apiSecret, apiAccessKeysName, apis } = response.data.data.attributes;
      const selectedApis = apis?.data?.map((api) => api.id) || [];
      setValue('name', apiAccessKeysName || '');
      setValue('apiKey', apiKey || '');
      setValue('apiSecret', apiSecret || '');
      setValue('selectedApis', selectedApis);
    } catch (error) {
      console.error('Error fetching API key details:', error);
    }
  };

  const onSubmit = async (data) => {
    setCustomError(''); 
  
    const isFormValid = await trigger(); 
    if (!isFormValid) {
      return;
    }
  
    try {
      if (editData) {
        const { id } = editData;
        const requestData = {
          apiAccessKeysName: data.name,
          apis: data.selectedApis,
        };
        await editKeyPair(id, { data: requestData });
      } else {
        const requestData = {
          apiKey: data.apiKey,
          apiSecret: data.apiSecret,
          apiAccessKeysName: data.name,
          apis: data.selectedApis,
        };
        const saveRes = await saveApiKeyAndToken(requestData);
        if (saveRes.status === 200) {
          console.log('API key and token saved successfully');
        } else {
          console.error('Failed to save API key and token');
        }
      }
      handleSave();
      handleClose();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          setCustomError('This name already exists. Please try another one.'); 
      } else {
        setCustomError('An unexpected error occurred. Please try again.');
      }
    }
  };
  
  

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">{editData ? 'Edit API Key' : 'Create API Key'}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ marginTop: 0 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm:'row', md: 'row' },
          gap: 2,
          paddingTop: 4,
        }}
      >
        <Box flex={1} pl={{ xs: 2, sm:5, md: 5 }} pr={{ xs: 2, sm:5, md: 4 }}>
          <Typography variant="h5">Name</Typography>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Name is required',
              minLength: { value: 3, message: 'Name must be at least 3 characters long.' },
              maxLength: { value: 50, message: 'Name must be less than 50 characters.' },
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                placeholder="Example: Development Environment API"
                variant="outlined"
                margin="normal"
                {...field}
                error={!!errors.name}
                onChange={(e) => {
                  setCustomError('');
                  field.onChange(e);
                }}
              />
            )}
          />
          <ErrorText>{errors.name?.message}</ErrorText>
          {customError && <ErrorText>{customError}</ErrorText>}
          <Typography variant="h5" mt={3}>Select API</Typography>
          <Controller
            name="selectedApis"
            control={control}
            rules={{ required: 'Select at least one API' }}
            render={({ field }) => (
              <Select
                size='large'
                mode="multiple"
                dropdownStyle={{ zIndex: 1500 }}
                showSearch={false}
                style={{ 
                  width: '100%',
                  marginTop:'16px',
                 }}
                placeholder="Please Select"
                onChange={(value) => {
                  field.onChange(value);
                  setValue('selectedApis', value); 
                }}
                {...field}
                value={field.value}
                error={!!errors.selectedApis}
              >
                {apiList.map((option) => (
                  <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
              </Select>
            )}
          />
          <ErrorText>{errors.selectedApis?.message}</ErrorText>
        </Box>
        <Box
          flex={1}
          pl={{ xs: 2, sm:3, md: 3 }}
          pr={{ xs: 2, sm:3, md: 8 }}
          pb={{ xs: 4, sm:3, md: 9 }}
          sx={{
            backgroundColor: 'rgba(245, 245, 245, 1)',
            mt: { xs: 2, sm:0, md: 0 },
          }}
        >          <Typography variant="h5">API Key</Typography>
          <TextField
            sx={{ backgroundColor: '#002453', color: '#ffffff' }}
            fullWidth
            {...register('apiKey')}
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
              style: { color: '#ffffff' },
            }}
          />
          <Typography variant="h5" mt={3}>API Secret Key</Typography>
          <TextField
            sx={{ backgroundColor: '#002453' }}
            fullWidth
            {...register('apiSecret')}
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
              style: { color: '#ffffff' },
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ marginTop: 0 }} />
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined" sx={{ padding: '0px 8px', minWidth: '64px' }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained" sx={{ padding: '2px 11px', minWidth: '64px' }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialogBox;
