import React from "react";
import { Select, Space } from "antd";
import classes from "./SelectDropdown.module.css";

export const SelectDropdown = ({
  menuOptions,
  placeholder,
  value,
  onChange,
  disabled = false,
  widthSize = "18rem",
}) => {
  const handleChange = (selectedValue) => {
    if (onChange) onChange(selectedValue);
  };

  return (
    <Select
      className={classes["select-dropdown"]}
      mode="single"
      onChange={handleChange}
      value={value}
      placeholder={placeholder ? placeholder : "select"}
      optionLabelProp="label"
      disabled={disabled}
      options={menuOptions}
      style={{
        width: widthSize,
      }}
      optionRender={(option) => (
        <Space>
          <span role="img" aria-label={option.data.label}>
            {option.data.label}
          </span>
        </Space>
      )}
    />
  );
};
