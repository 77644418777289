import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Typography, Paper, Box } from "@mui/material";

const CreditCircularProgress = ({ remainingCredits, totalCredits }) => {
  const circleRatio = 0.5;
  let progressValue = (remainingCredits / totalCredits) * 100;
  if (isNaN(progressValue)) {
    progressValue = 0;
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
      }}
    >
      <Box sx= {{margin: "21px 20px 0px 20px"}}>
      <CircularProgressbar
        circleRatio={circleRatio}
        value={progressValue}
        styles={{
          root: { transform: "rotate(0.75turn)", width: "160px" },
          path: { stroke: "#007bff", strokeLinecap: "butt" },
          trail: { stroke: "#C4C4C4", strokeLinecap: "butt" },
          trailColor: "grey",
        }}
      />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "-50px" }}>
        <Typography sx={{ color: "#007bff", fontWeight: "bold" }} variant="h4">
        {remainingCredits}
        </Typography>
        <Typography variant="h4">{`/${totalCredits}`}</Typography>
      </Box>
      <Typography sx={{ mt: 1, mb: 3 }} variant="h4">
        Credit Balance
      </Typography>
    </Paper>
  );
};

export default CreditCircularProgress;
