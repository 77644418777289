import apiCall from "../utils/api-call/apiCall";
import { createHeaders } from "./constants";

const generateApiKeyAndToken = async () => {
  try {
    const headers = createHeaders();
    return await apiCall('generate-keys', 'GET', {}, headers);
  } catch (error) {
    throw error;
  }
};

export { generateApiKeyAndToken };