import { Box } from "@mui/material";
export const DataColumns = [
  {
    title: "API Name",
    dataIndex: "apiName",
  },
  {
    title: "Request Time",
    dataIndex: "requestTime",
  },
  {
    title: "Response Time",
    dataIndex: "responseTime",
  },
  
  {
    title: "HTTP Status",
    dataIndex: "httpStatus",
  },
  {
    title: "Status",
    dataIndex: "status",
  },

  {
    title: "Request ID",
    dataIndex: "sequenceID",
  },

  {
    title: "Credit Utilized",
    dataIndex: "creditUtilized",
  },
];

export const Data = [
  {
    key: "1",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="#176C02">
        Fetched
      </Box>
    ),
    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "2",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="#176C02">
        Fetched
      </Box>
    ),
    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "3",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="#176C02">
        Fetched
      </Box>
    ),
    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "4",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="#FF4A4A">
        Fetched
      </Box>
    ),    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "5",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="green">
        Fetched
      </Box>
    ),    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "6",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="green">
        Fetched
      </Box>
    ),    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "7",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="#FF4A4A">
        Fetched
      </Box>
    ),    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "8",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="green">
        Fetched
      </Box>
    ),    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "9",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="green">
        Fetched
      </Box>
    ),    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
  {
    key: "10",
    apiName: "Aadhaar Masking",
    requestTime: "25-01-2024, 9:18 AM",
    httpStatus: 200,
    status: (
      <Box component="span" color="#FF4A4A">
        Fetched
      </Box>
    ),    sequenceID: "D3044f78-C92e-4f28-B909-95b72d08cfbf",
  },
];
