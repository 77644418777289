import { Paper, Button, Box } from "@mui/material";
import { RequestCredit } from "../../assets";
import { useNavigate } from "react-router-dom";

export const RequestCreditComponent = () => {
    const navigate = useNavigate();
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding:'20px'
      }}
    >
      <Box component="img" sx={{ marginBottom: "20px", width:{sx:'70%',sm:'40%',md:'70%'}}} src={RequestCredit} alt="Request Credit" />
      <Button sx={{padding: '3px 5px', fontSize:'15px'}} variant="contained" onClick={() => navigate("/contact-us")}>Request for more credits</Button>
    </Paper>
  );
};
