import { useState, useEffect, useMemo } from 'react';
import {
  Box, Button, Paper, Stack, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApexPieChart from '../../charts/pie-charts/dashboard';
import { DataColumns } from './constants';

import {
  Wrapper,
  SelectDropdown,
  CustomDivider,
  CustomTable,
  TableButton,
  Calender,
} from '../../components';
import {
  Logs,
  apiCategortList,
  dashboardCharts,
  downloadLogs,
  usageLogs,
} from '../../services';
import DateTimeGraph from '../../charts/line-charts/dashboard';
import dayjs from 'dayjs';

export const groupSimilarData = (arr = []) => {
  const apiCategory = arr.map(
    (item) => item?.attributes?.apiCategory?.data?.attributes?.name,
  );

  const apiCategoryObj = {};
  apiCategory.forEach((element) => {
    apiCategoryObj[element] = [];
  });

  arr.forEach((item) => apiCategoryObj[item?.attributes?.apiCategory?.data?.attributes?.name].push({
    name: item?.attributes?.name,
    id: item?.id,
  }));
  return apiCategoryObj;
};

export function Dashboard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // change here
  const { user } = useSelector((state) => state.auth);
  const jwtToken = user?.jwt;
  const navigate = useNavigate();

  const [dashboardChartsData, setDashboardChartsData] = useState({});
  const [dashboardUsageData, setDashboardUsageData] = useState([]);
  const [apiCategoryAndList, setApiCategoryAndList] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedAPI, setSelectedAPI] = useState('');
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isCalendarChanged, setIsCalendarChanged] = useState(false); // New state

  const currentDate = dayjs();
  const sevenDaysBack = currentDate.subtract(7, 'day');

  const defaultDateRange = [sevenDaysBack.startOf('day'), currentDate.endOf('day')];
  const [dateRangeValue, setDateRangeValue] = useState(defaultDateRange);

  const [dashboardDates, setDashboardDates] = useState({
    startDate: defaultDateRange[0].format('YYYY-MM-DD'),
    endDate: defaultDateRange[1].format('YYYY-MM-DD'),
  });

  useEffect(() => {
    const getApiCategoryList = async () => {
      try {
        const res = await apiCategortList();
        if (res?.status === 200) {
          const categories = res?.data?.data?.attributes?.apisByCategory || {};
          setApiCategoryAndList(categories);
          const sortedCategories = Object.keys(categories).sort();
          setSelectedCategory('all');
        }
      } catch (error) {
        Logs(error);
      }
    };

    getApiCategoryList();
  }, []);

  useEffect(() => {
    const getDashboardCharts = async () => {
      try {
        const res = await dashboardCharts(selectedCategory === 'all' ? null : selectedAPI, dashboardDates);
        if (res?.status === 200) {
          setDashboardChartsData(res?.data);
        }
      } catch (error) {
        console.error('Error fetching dashboard charts data:', error);
      }
    };

    if (selectedCategory) {
      getDashboardCharts();
    }
  }, [selectedAPI, dashboardDates]);

  useEffect(() => {
    if (selectedAPI || selectedCategory === 'all') {
      const fetchUsageLogs = async () => {
        try {
          const res = await usageLogs({
            token: jwtToken,
            startDate: dashboardDates?.startDate,
            endDate: dashboardDates?.endDate,
            apiId: selectedCategory === 'all' ? null : selectedAPI,
          });
          if (res?.status === 200) {
            setDashboardUsageData(res?.data);
          }
        } catch (error) {
          console.error('Error fetching usage logs:', error);
        }
      };
      fetchUsageLogs();
    }
  }, [selectedAPI, dashboardDates, jwtToken]);

  useEffect(() => {
    setIsFilterActive(selectedCategory !== 'all' || isCalendarChanged);
    if (selectedCategory !== 'all' && apiCategoryAndList[selectedCategory]?.apis) {
      const sortedApis = apiCategoryAndList[selectedCategory]?.apis.sort((a, b) => a.name.localeCompare(b.name));
      setSelectedAPI(sortedApis[0]?.id || '');
    } else {
      setSelectedAPI('');
    }
  }, [selectedCategory, apiCategoryAndList, isCalendarChanged]);

  const { apiOptions } = useMemo(() => {
    const selected = apiCategoryAndList[selectedCategory] || [];
    if (selected?.apis?.length > 0) {
      const sortedApiOptions = selected?.apis.map((item) => ({
        label: item.name,
        value: item.id,
      })).sort((a, b) => a.label.localeCompare(b.label));

      const selectedApi = selected.apis.find((x) => x.id === selectedAPI);
      return { apiOptions: sortedApiOptions, api: selectedApi };
    }
    return { apiOptions: [], api: null };
  }, [selectedCategory, apiCategoryAndList, selectedAPI]);

  const handleChangeCalender = (dates) => {
    if (dates) {
      setDashboardDates({
        startDate: dates[0].format('YYYY-MM-DD'),
        endDate: dates[1].format('YYYY-MM-DD'),
      });
      setDateRangeValue(dates);
      setIsCalendarChanged(true);
    } else {
      setDashboardDates({
        startDate: defaultDateRange[0].format('YYYY-MM-DD'),
        endDate: defaultDateRange[1].format('YYYY-MM-DD'),
      });
      setDateRangeValue(defaultDateRange);
      setIsCalendarChanged(true);
    }
  };

  const handleClick = async (id) => {
    try {
      const response = await downloadLogs(selectedCategory === 'all' ? null : id, dashboardDates);
      if (response?.data) {
        const url = URL.createObjectURL(response?.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'logs.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleResetFilter = () => {
    setSelectedCategory('all');
    setSelectedAPI('');
    setDashboardDates({
      startDate: defaultDateRange[0].format('YYYY-MM-DD'),
      endDate: defaultDateRange[1].format('YYYY-MM-DD'),
    });
    setDateRangeValue(defaultDateRange);
    setIsCalendarChanged(false);
  };

  return (
    <Wrapper>
      <Stack sx={{ pt: 4 }} spacing={3} mt={6} className="wrapper">
        <Typography
          sx={{ color: theme.palette.textColor.primary }}
          variant="h3"
        >
          Usage Summary
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '1rem' }}>
          <Stack spacing={1} sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}>
            <Typography variant="body1" sx={{ color: theme.palette.grey.darken4 }}>
              Type of API
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
              <SelectDropdown
                menuOptions={[{ label: 'All', value: 'all' }, ...Object.keys(apiCategoryAndList)
                  .sort()
                  .map((category) => ({
                    label: category,
                    value: category,
                  }))]}
                value={selectedCategory}
                onChange={(value) => setSelectedCategory(value)}
                widthSize={isSmallScreen ? '100%' : '22rem'}
              />
              <CustomDivider orientation="vertical" />
            </Box>
          </Stack>

          <Stack spacing={1} sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}>
            <Typography variant="body1" sx={{ color: theme.palette.grey.darken4 }}>
              APIs
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
              <SelectDropdown
                menuOptions={apiOptions}
                value={selectedAPI}
                onChange={setSelectedAPI}
                widthSize={isSmallScreen ? '100%' : '22rem'}
                disabled={selectedCategory === 'all'}
              />
              <CustomDivider orientation="vertical" />
            </Box>
          </Stack>

          <Stack spacing={1} sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}>
            <Typography variant="body1" sx={{ color: theme.palette.grey.darken4 }}>
              Select date
            </Typography>
            <Calender
              handleChange={handleChangeCalender}
              defaultValue={defaultDateRange}
              value={dateRangeValue}
            />
          </Stack>

          {isFilterActive && (
            <Button
              onClick={handleResetFilter}
              variant="contained"
              sx={{ alignSelf: 'end', maxHeight: '32px' }}
            >
              Reset Filter
            </Button>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: isSmallScreen ? 'column' : 'row' }}>
          <Box sx={{ flex: 0.7 }}>
            <Paper
              sx={{ height: '100%', boxShadow: theme.palette.shadow.blue }}
            >
              <DateTimeGraph data={dashboardChartsData?.areaChart} />
            </Paper>
          </Box>
          <Box sx={{ flex: 0.3 }}>
            <Paper
              sx={{
                height: '100%',
                boxShadow: theme.palette.shadow.blue,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ApexPieChart
                piechartData={
              dashboardChartsData.pieChart
                ? dashboardChartsData.pieChart
                : null
            }
              />
            </Paper>
          </Box>
        </Box>

        <Stack spacing={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Typography
              sx={{ color: theme.palette.textColor.primary }}
              variant="h3"
            >
              Usage Logs
            </Typography>
            <Button
              onClick={() => handleClick(selectedAPI, dashboardDates)}
              variant="contained"
              disabled={dashboardUsageData.length === 0 || dashboardUsageData.every((entry) => entry.apiUsed === 0)}
            >
              Download logs
            </Button>

          </Box>
          <Stack>
            <CustomTable
              columns={DataColumns}
              data={dashboardUsageData.map((item, index) => ({
                ...item,
                usage: (
                  <TableButton
                    key={index}
                    onClick={() => {
                      navigate('/view-logs', { state: { date: item?.date, selectedApiId: selectedAPI } });
                    }}
                  >
                    View Logs
                  </TableButton>
                ),
                key: index,
              }))}
            />
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>

  );
}
