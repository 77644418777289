import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordSchema } from './validations';
import { forgotPasswordApi } from '../../services/authService';
import { finLogo, ForgotEmail, FooterLogo } from '../../assets';
import { ErrorText } from '../../components';
import { Logs } from '../../services';
import { notification } from 'antd';
import { Link } from 'react-router-dom';

export const ForgotPassword = ({ handleBackLogin }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showSuccess, setShowSuccess] = useState(false);
  const [apiResponseError, setApiResponseError] = useState(false);
  const form = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    mode: 'all',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const handleForgotPasswordClick = async (data) => {
    try {
      const email = data?.email.toLowerCase();
      const res = await forgotPasswordApi(email);
      if (res.status === 200){
        setShowSuccess(true);
        notification.success({
          message: "Password reset link sent!",
          description: "Check Your Spam Folder If You Don't See It In Your Inbox."
        })
      }
    } catch (error) {
      setApiResponseError(error?.response?.data?.error?.message);
      Logs(error);
    }
  };

  const handleEmailChange = (event) => {
    setApiResponseError(null); 
    form.setValue('email', event.target.value);
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(handleForgotPasswordClick)}
        sx={{
          background: 'white',
          width: '100%',
          maxWidth: '30rem',
          minWidth: { xs: '90%', sm: '48rem' },
          padding: { xs: '24px 16px', sm: '24px 64px' },
          overflow: 'auto',
          margin: "10px 0"
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: { xs: 0, md: 1 },
            }}
          >
            <Box
              component="img"
              src={finLogo}
              sx={{ width: '120px', height: 'auto' }}
            />
          </Box>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Forgot Password
          </Typography>

          <Box>
            {apiResponseError && <ErrorText>{apiResponseError}</ErrorText>}
            <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
              Email
            </Typography>
            <TextField
              fullWidth
              id="email"
              type="email"
              placeholder="Enter your Email id"
              {...register('email')}
              onChange={handleEmailChange}  // Add the onChange handler here
            />
            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            <Typography
              variant="body1"
              sx={{ mt: 2, color: theme.palette.grey.darken }}
            >
              Please provide your email to get reset password link.
            </Typography>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Button
              sx={{ fontSize: '1.8rem', fontWeight: 600 }}
              type="submit"
              variant="contained"
              fullWidth
            >
              Submit
            </Button>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                sx={{
                  fontSize: "16px",
                  fontWeight: "medium",
                  '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={handleBackLogin}
              >
                Back to Log in
              </Button>
            </Box>
          </Box>
        </Stack>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: "40px" }}
        >
        <Box component="img" src={FooterLogo} />
          <Typography ml={0.5} sx={{ fontSize: '16px', color: '#ffffff' }}>
            <Link
              to="https://www.habilelabs.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#757575' }}
              onMouseEnter={(e) => {
                e.target.style.color = "#1976d2";
                e.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "#757575";
                e.target.style.textDecoration = "none";
              }}
            >
              Powered by Habilelabs
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
